<template>
  <div :key="appKey">
    <!-- Cabeçalho -->
    <section class="px-5 pt-28 pb-12 2xl:pt-36 xs:px-12 text-center bg-oxford-blue">
      <h1 class="text-4xl lg:text-5xl text-white font-bold mb-10">
        {{ translate("find the international jobs you'd always hoped for", 'ucfirst') }}
      </h1>
    </section>

    <!-- Job serach filters -->
    <JobFiltersComponent
      :show="showJobFilter"
      @exportSelectedFilters="performSeachWithAdvancedFilters"
      @closed="showJobFilter = false"
    />

    <!-- Formulários de pesquisa -->
    <section
      class="flex gap-3 md:gap-0 sm:w-full md:w-5/6 lg:w-3/4 mx-auto py-4 px-4 lg:px-3 md:-mt-10 justify-center"
    >
      <form 
        @submit.prevent="searchOffers" 
        :class="!isScreenSmall() ? 'w-full flex bg-white rounded-lg shadow-md' : 'w-full flex bg-white rounded-lg shadow-md flex-col'"
      >
        <InputGroup :class="!isScreenSmall() ? 'w-full' : 'w-full mb-3'">
          <InputGroupAddon>
            <i class="pi pi-search"></i>
          </InputGroupAddon>
          <InputText 
            v-model="jobs.jobNameFilter"
            :placeholder="translate('job name')" 
            class="w-full outline-none py-2 px-3" 
          />
        </InputGroup>
        <InputGroup class="w-full hidden md:flex">
          <InputGroupAddon>
            <i class="pi pi-map"></i>
          </InputGroupAddon>
          <InputText 
            v-model="jobs.jobLocationFilter"
            :placeholder="translate('location', 'ucfirst')" 
            class="w-full outline-none py-2 px-3" 
          />
        </InputGroup>
        <InputGroup :class="!isScreenSmall() ? 'w-full flex' : 'w-full flex mb-3'" >
          <InputGroupAddon>
            <i class="pi pi-sort-alpha-up"></i>
          </InputGroupAddon>
          <!-- <MultiSelect
            v-model="selectedCategories"
            :options="groupedCategories"
            optionLabel="name"
            :maxSelectedLabels="4"
            placeholder="Todas categorias"
            class="w-full outline-none"
          >
          </MultiSelect> -->
          <Button 
            :label="translate('advanced filters', 'ucfirst')"
            @click.prevent="showJobFilter = true;"
          />
        </InputGroup>
        <Button
          type="submit"
          :loading="jobs.loading"
          @click.prevent="getJobs()"
          :label="translate('search', 'ucfirst')"
          :class="!isScreenSmall() ? 'w-3/4 text-white rounded-md bg-sea-serpent ps-2' : 'md:flex md:ml-3 py-1 px-4 md:pl-3 md:pr-12 text-white rounded-md bg-sea-serpent py-3'"
        />
      </form>
    </section>

    <section
      class="w-full px-5 pt-5 md:w-3/4 flex justify-center gap-4 md:mx-auto"
      :style="{display: 'none'}"
    >
      <Carousel
        :value="defaulCategories"
        :numVisible="3"
        :numScroll="1"
        :showNavigators="false"
        :responsiveOptions="responsiveCategories"
      >
        <template #item="slotProps">
          <div class="px-4">
            <span
              class="w-auto cursor-pointer bg-white rounded-md border border-iron py-1 px-2"
            >
              {{ slotProps.data.name }}
            </span>
          </div>
        </template>
      </Carousel>
    </section>

    <section v-if="!jobs.loading" class="flex md:pl-3 md:pr-3 pt-20 relative lg:w-3/4 md:w-6/7 sm:w-full mx-auto">
      <div :class="!isScreenSmall() && jobs.list.length > 0 ? 'w-2/4 px-1 md:px-1 pe-2 me-2' : 'w-full px-1 md:px-1 ps-2 pe-2'">
        <p>{{ translate('total jobs added on Jobifull', 'ucfirst') }} {{ jobs.totalJobsOnDB }}.</p>
      </div>
    </section>

    <!-- seção principal -->
    <section class="flex mb-36 md:pl-3 md:pr-3 relative lg:w-3/4 md:w-6/7 sm:w-full mx-auto">
      <!-- Side filter bars  -->
      <SideBarFilters
        @filters="filtersTypes"
        @filter-by-salary="filterBySalary"
        class="hidden md:flex"
        :style="{ display: 'none'}"
      />

      <!-- Jobs list -->
      <div v-if="jobs.loading" class="w-full text-center">
        <LoaderComponent />
      </div>
      <div 
        :class="!isScreenSmall() && jobs.list.length > 0 ? 'w-2/4 px-1 md:px-1 pe-2 me-2' : 'w-full px-1 md:px-1 ps-2 pe-2'"
        v-if="!jobs.loading"
      >
        <div class="flex flex-wrap md:flex-nowrap justify-between items-center mb-3" v-if="jobs.list.length > 0">
          <p :class="!isScreenSmall() ? 'text-lg w-full' : 'text-lg w-full pb-2'">
            {{ translate('showing', 'ucfirst') }} 
            <strong class="font-medium">{{ jobs.showing }}</strong> 
            {{ translate('of') }}
            {{ jobs.totalNotShowing }}
            {{ translate('results') }}.
          </p>
          <span :class="!isScreenSmall() ? 'w-full flex content-center items-center justify-end' : 'w-full text-right'">
            <span v-if="!isScreenSmall()" class="w-auto me-2">
              {{ translate('sort by', 'ucfirst') }}:
            </span>
            <Dropdown
              v-model="selectedSort"
              :options="sort"
              optionLabel="name"
              optionValue="code"
              placeholder="Selecionar"
              :class="!isScreenSmall() ? 'w-full md:w-44' : 'w-full text-left'"
              @change="sortJobs()"
            />
          </span>
        </div>

        <!-- jobs card -->
        <template v-if="jobs.list.length > 0">
          <Card
            v-for="(job, index) in jobs.jobsParsedByPage[jobs.current_page]"
            @click="jobs.jobSelected = job;"
            :key="'job-' + index"
            class="w-full shadow-md mb-5 transition-colors duration-700 hover:border job-card cursor-pointer job-card"
          >
            <template #content>
              <div class="flex flex-wrap gap-3">
                <!-- logo -->
                <div
                  :style="{ backgroundImage: `url(${job.company_logo})` }"
                  class="w-20 h-20 rounded-lg border border-iron profile-photo"
                ></div>

                <div class="w-full flex flex-col">
                  <h1 
                    class="text-lg font-bold text-oxford-blue" 
                  >
                    <span class="cursor-pointer underline" @click.prevent="openJobDetailsPage(job.job_id, job)">
                      {{ ucfirst(job.job_title) }}
                      <i class="pi pi-external-link"></i>
                    </span>
                  </h1>
                  <div class="flex gap-x-3">
                    <span class="flex items-center text-sm"
                      ><i class="pi pi-building mr-1" style="font-size: 0.95em"></i>
                      {{ ucfirst(job.company_name) }}
                    </span>
                    <span class="flex items-center text-sm"
                      ><i class="pi pi-clock mr-1" style="font-size: 0.95em"></i> há
                      {{ job.posted_at }}</span
                    >
                  </div>

                  <div class="flex gap-x-3" v-if="store.getters.loggedAsProfessional">
                    <span class="flex items-center text-sm" v-tooltip.top="translate('match with filters', 'ucfirst')" type="text" placeholder="Top">
                      <i class="pi pi-percentage mr-1" style="font-size: 0.95em"></i>
                      {{ job.match }} {{ translate('of match') }}
                    </span>
                  </div>

                  <div :class="windowWidth > breakPoint ? 'flex gap-x-5 mt-1' : 'flex flex-col'">
                    <h6 class="flex items-center text-sm gap-x-2">
                      <i class="pi pi-map-marker" style="font-size: 1.3rem"></i>
                      {{ ucfirst(job.location) }}
                    </h6>
                    <h6 class="flex items-center text-sm gap-x-2">
                      <i class="pi pi-briefcase" style="font-size: 1.3rem"></i>
                      {{ job.job_modality }}
                    </h6>
                    <h6 class="flex items-center text-sm gap-x-2" v-if="job.experience_in_months > 1">
                      <i class="pi pi-calendar" style="font-size: 1.3rem"></i>
                      {{ job.exp_required }}
                    </h6>
                  </div>
                </div>
              </div>
            </template>
            <template #footer>
              <div
                class="flex justify-between items-center -mb-3 pt-3 border-t border-iron"
              >
                <p class="text-lg font-semibold text-oxford-blue">
                  <i class="pi pi-money-bill mr-2"></i> {{ job.minimunWage == 0 && job.maxWage == 0 ? translate('not specified', 'ucfirst') : job.salary }}
                </p>
              </div>
            </template>
          </Card>
        </template>
        <template v-else>
          <div class="w-full text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3em;"></i>
            <h1 class="text-lg font-bold text-oxford-blue">{{ translate('your search did not match any of our jobs', 'ucfirst') }}.</h1>
            <h4 class="my-3 font-semibold">{{ translate('feel free to try again using one of our filters at "Advanced filters"', 'ucfirst') }}.</h4>
          </div>
        </template>

        <!-- Pagination -->
        <div class="c-paginator w-full mt-9" v-if="jobs.list.length > 0">
          <Paginator
            @page="changePage"
            :rows="jobs.per_page"
            :totalRecords="jobs.max"
          ></Paginator>
        </div>
      </div>

      <!-- Job detail -->
      <JobDetailPageComponent 
        v-if="!isScreenSmall()"
        :class="'w-2/4 ms-3 5md:w-2/5 sticky top-24 h-[calc(100vh-theme(spacing.44))] pb-14 rounded-l-lg bg-white overflow-y-auto job-details'"
        :job="jobs.jobSelected"
        :showLoader="false"
        :card="true"
        :goBackButton="true"
        :appKey="appKey"
      />
    </section>

    <Sidebar
      v-model:visible="visible"
      position="bottom"
      style="height: 600px; padding: 5px 6px"
    >
      <template #header>
        <p></p>
      </template>
      <div
        class="flex flex-col items-center gap-y-4 font-semibold rounded-t-xl bg-white py-5 px-4 md:px-12 text-oxford-blue"
      >
        <ul
          class="flex flex-col md:flex-row space-y-1 md:space-y-0 md:space-x-6 lg:space-x-7"
        >
          <li>Home</li>
          <li>Vagas</li>
          <li>Profissionais</li>
          <li>Empresas</li>
          <li>Planos</li>
        </ul>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount, onMounted, onUnmounted, watch } from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Button from "primevue/button";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Paginator from "primevue/paginator";
import InputText from "primevue/inputtext";
import Sidebar from "primevue/sidebar";
import Carousel from "primevue/carousel";
import SideBarFilters from "@/components/Filters/SidebarJobs";
import requestHandler from "@/composables/requestHandler";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import { translate } from "@/composables/translate";
import store from "@/store";
import { ucfirst } from "@/composables/textUtils";
import JobDetailPageComponent from "@/components/Job/JobDetailPageComponent"
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";
import JobFiltersComponent from "@/components/Job/JobFiltersComponent"
import utils from "@/composables/utils";
import { sleep } from "@/composables/sleep";
import jobData from "@/composables/jobData";

// ############ Declaração de variáveis ############

const visible = ref(false);
const toast = useToast();

let showJobFilter = ref(false);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const breakPoint = 768;

const filters = reactive({
  salary: 8000,
  types: [],
  seniorities: [],
  idioms: [],
});

const defaulCategories = reactive([
  { name: "Design" },
  { name: "Engenharia de Software" },
  { name: "Marketing" },
  { name: "Programação" },
  { name: "Gestão de Projetos" },
  { name: "Construção" },
]);

const selectedSort = ref('most_recent');
const sort = reactive([
  { name: translate('most recent', 'ucfirst'), code: "most_recent"},
  { name: translate('older', 'ucfirst'), code: "older"},
  { name: translate('higher wage', 'ucfirst'), code: "higher_wage"},
  { name: translate('lowest wage', 'ucfirst'), code: "lowest_wage"},
]);

const responsiveCategories = ref([
  {
    breakpoint: "1400px",
    numVisible: 6,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 5,
    numScroll: 2,
  },
  {
    breakpoint: "768px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: "440px",
    numVisible: 2,
    numScroll: 1,
  },
]);

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############
let jobs = reactive({
  list: [],
  current_page: 1,
  per_page: 10,
  last_page: 10,
  total: 0,
  max: 0,
  loading: false,
  jobsParsedByPage: [],
  jobSelected: null,
  filters: [],
  jobNameFilter: null,
  jobLocationFilter: null,
  showing: 0,
  totalNotShowing: 0,
  totalJobsOnDB: 0
});

let filterNonArrayKeys = ['job_salary_end','job_salary_start'];
function performSeachWithAdvancedFilters(obj){
  let filterKeys = Object.keys(obj.filters);
  let data = obj.filters;
  let filters = [];
  for(let key of filterKeys){
    let arr = data[key];
    if(arr == null || arr.length == 0)
      continue;
    if(filterNonArrayKeys.includes(key)){
      filters.push({name: key, val: arr})
    }else{
      let ids = [];
      for(let filterVal of arr){
        ids.push(filterVal.code);
      }
      filters.push({name: key, val: ids});
    }
  }
  jobs.filters = filters;
  showJobFilter.value = false; 
  getJobs();
}

async function getJobs(page = 1, useFromMemory = false) {
  jobs.jobSelected = null;
  if(typeof page === 'object' && page.page !== undefined)
    page = page.page + 1;
  jobs.loading = true;
  let listOfJobs = [];
  let oldResults = [];
  let storeJoblist = false;
  let totalOfJobsOnDB = 0;
  if(useFromMemory && store.getters.getOnDataChest('job_view_old_results'))
    oldResults = store.getters.getOnDataChest('job_view_old_results');
  if(oldResults.length < 1){
    let params = {per_page: 100};
    if(jobs.filters.length > 0){
      for(let key of jobs.filters){
        params[key.name] = key.val;
      }
    }
    if(jobs.jobNameFilter)
      params['free_term'] = jobs.jobNameFilter;
    if(jobs.jobLocationFilter)
      params['location'] = jobs.jobLocationFilter;
    let formerParams = store.getters.getOnDataChest('job_view_search_params');
    if(!utils.areObjectsEqual(formerParams, params, ['user_lang'])){
      store.dispatch('saveOnDataChest', {
        key: 'job_view_search_params',
        value: params
      });
      storeJoblist = true;
    }
    storeJoblist = true;
    if(storeJoblist){
      const response = await requestHandler.executeRequest('api/joblist/index', params, 'get');
      if(response.success){
        listOfJobs = response.data.data;
        totalOfJobsOnDB = response.data.total;
      }else{
        listOfJobs = [];
      }
      store.dispatch('saveOnDataChest', {
        key: 'job_view_job_list',
        value: response.data.data
      });
    }else{
      jobs.jobSelected = null;
      jobs.loading = true;
      await sleep(500);
      listOfJobs = store.getters.getOnDataChest('job_view_job_list');
    }
    store.dispatch('saveOnDataChest', {
      key: 'job_view_old_results',
      value: listOfJobs
    });
  }else{
    jobs.jobSelected = null;
    listOfJobs = oldResults;
  }
  jobs.loading = false;
  jobs.list = listOfJobs;
  jobs.totalNotShowing = listOfJobs.length;
  jobs.totalJobsOnDB = totalOfJobsOnDB;
  paginateJobs(storeJoblist);
}

function paginateJobs(handleImages = true){
  jobs.jobsParsedByPage = [];
  jobs.current_page = 1;
  jobs.total = jobs.list.length;
  jobs.last_page = Math.ceil(jobs.total / jobs.per_page);
  jobs.max = jobs.per_page * jobs.last_page;

  let index = 0;
  let indexPage = 0;
  for(let job of jobs.list){
    if(index == jobs.per_page){
      index = 0;
      indexPage++;
    }
    if(jobs.jobsParsedByPage[indexPage] === undefined)
      jobs.jobsParsedByPage[indexPage] = [];
    if(handleImages){
      job = jobData.readJobImages(job);
    }
    jobs.jobsParsedByPage[indexPage].push(job);
    if(jobs.jobSelected == null){
      jobs.jobSelected = job;
    }
    index++;
  }
  changePage(0);
}

function sortJobs(){
  let sortedJobs = [];
  switch(selectedSort.value){
    case 'most_recent':
      sortedJobs = jobs.list.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    break;
    case 'older':
      sortedJobs = jobs.list.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    break;
    case 'higher_wage':
      sortedJobs = jobs.list.sort((a, b) => b.max_wage - a.max_wage);
    break;
    case 'lowest_wage':
      sortedJobs = jobs.list.sort((a, b) => a.max_wage - b.max_wage);
    break;
  }
  jobs.list = sortedJobs;
  paginateJobs(false);
}

function changePage(pageNumber) {
  let thisPage = pageNumber.page != undefined ? pageNumber.page : pageNumber;
  if(thisPage != jobs.current_page)
    window.scrollTo(0,0);
  jobs.current_page = thisPage;
  jobs.showing = 0;
  if(jobs.list.length < 1){
    jobs.jobSelected = null;
  }else{
    if(jobs.jobsParsedByPage[jobs.current_page] != undefined && jobs.jobsParsedByPage[jobs.current_page].length > 0){
      jobs.jobSelected = jobs.jobsParsedByPage[jobs.current_page][0];
      jobs.showing = jobs.jobsParsedByPage[jobs.current_page].length;
      if(document.querySelectorAll('.job-details').length > 0)
        document.querySelectorAll('.job-details')[0].scrollTop = 0 
    }else{
      jobs.jobSelected = null;
    }
  }
}

async function openJobDetailsPage(job_id, jobData = null){
  store.dispatch('saveOnDataChest', {
    key: 'job_details_view_selected_job',
    value: jobData
  });
  await requestHandler.redirect(`job/${job_id}`);
}

function searchOffers() {}

function filterBySalary() {}

function filtersTypes() {
  filters.salary = 100;
}

//############ Propriedades Computadas ############

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############

function handleResize(){
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}
function isScreenSmall(){
  return windowWidth.value > breakPoint ? false : true;
}

let props = defineProps(['appKey']);
let appKey = ref();
onMounted(() => {
  appKey.value = props.appKey;
  window.addEventListener('resize', handleResize);
  if(store.getters.hasSessionManage){
    const sessionMessageData = store.getters.getSessionMessage;
    showToast(toast, sessionMessageData.message, sessionMessageData.type);
    store.dispatch('cleanSessionMessage');
  }
});

watch(() => props.appKey, (newAppKey) => {
  appKey.value = newAppKey;
  getJobs();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

onBeforeMount(() => {
  // let formerParams = store.getters.getOnDataChest('job_view_search_params');
  // if(!utils.isEmpty(formerParams)){
  //   jobs.filters = {};
  //   for(let attr of Object.keys(formerParams)){
  //     if(attr == 'free_term'){
  //       jobs.jobNameFilter.value = formerParams['free_term'];
  //     }else if(attr == 'location'){
  //       jobs.jobLocationFilter.value = formerParams['location'];
  //     }else{
  //       jobs.filters[attr] = formerParams[attr];
  //     }
  //   }
  // }
  getJobs(1, true);
});

</script>

<style scoped></style>
