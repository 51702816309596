<template>
  <div>
    <LoadingDialog :visible="loading" />

    <section class="px-5 lg:px-8 my-10">
    <div class="flex flex-col md:flex-row justify-between items-center p-5 lg:p-8 bg-white shadow-lg rounded-lg">
      <span>
        <h1 class="text-oxford-blue mb-2 text-3xl font-semibold">{{ translate('manage Recruiters', 'ucfirst') }}</h1>
        <p class="text-base text-gray-500 font-normal">Gerencie os recrutadores para sua empresa e os seus acessos.</p>
      </span>
      <span>
        <AutoComplete
          inputId="recruiterName"
          v-model="recruiterName" 
          :suggestions="recruiterList" 
          optionLabel="name"
          @complete="getRecruiters"
          :placeholder="`${translate('type the name or email of the recruiter', 'ucfirst')}...`"
          :emptyMessage="translate('no results found', 'ucfirst')"
          :emptySearchMessage="translate('no results found', 'ucfirst')"
          :inputClass="'w-full h-full'"
          fluid 
          minLength="3"
          class="w-full p-1 rounded border border-iron autocomplete-w-full"
        />
        <Button 
          @click="sendInvitationToRecruiter" 
          rounded 
          :disabled="recruiterName.length == 0 ? true : false" 
          class="px-3 py-1.5 mt-4 bg-sea-serpent text-white font-semibold" 
          icon="pi pi-envelope"
          label="Enviar Convite" 
        />
      </span> 
      </div>
    </section>


    <!-- Seção lista recrutadores -->
    <section class="w-full lg:px-8 mb-20"> 
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <ul>
          <li class="flex justify-between text-oxford-blue font-semibold px-5 py-3 mb-1.5 bg-sea-serpent oxford-blue gray-200">
            <h3 class="w-1/3">{{ translate('recruiter', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('contact', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('tasks', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('status', 'ucfirst') }}</h3>
            <h3 class="w-1/5 md:pl-4">{{ translate('added at', 'ucfirst') }}</h3>
            <h3 class="w-1/6 md:pl-4">{{ translate('actions', 'ucfirst') }}</h3>
          </li> 
          
          <div class="text-center w-full py-5" v-if="loadingCompanyRecruiters">
            <LoaderComponent/>
          </div>
          <template v-if="!loadingCompanyRecruiters && currentRecruiters.length > 0">
            <li v-for="(recruter, index) in currentRecruiters" :key="index"
              class="flex justify-between items-center p-3 border-b border-iron">
              <!-- Nome e foto recrutador -->
              <div class="flex items-center gap-3 w-1/3">
                <img :src="recruter.recruiter_photo" class="w-9 rounded-full">
                <span>
                  <h2 class="text-oxford-blue font-medium">{{ recruter.person_username }}</h2>
                  <small class="text-sm text-gray-500 font-normal">{{ recruter.person_email }}</small>
                </span>
              </div> 
              <p class="flex items-center px-4 w-1/5">{{ recruter.person_ddi }} {{ recruter.person_phone }}</p>
              <!-- Tarefas do recrutador -->
              <span class="flex flex-wrap items-center px-4 w-1/3">
                <Tag v-for="(task, index) in recruter.tasks" :key="index" :value="task"
                  :severity="task == 'Eliminar' ? 'danger' : 'success'" class="m-1"></Tag>
              </span>
              <span class="px-4 w-1/3">
                <Tag :value="translate(recruter.status, 'ucfirst')"
                  :severity="getSeverityOfRecuriter(recruter.status)" class="m-1"></Tag>
              </span>
              <!-- data adicionado recrutador -->
              <strong class="flex items-center px-4 w-1/5">{{ recruter.createdAt }}</strong>
              <span class="flex flex-wrap items-center px-4 w-1/6"> 
                <Tag @click="removeCompanyRecruiter(recruter.company_recruiter_id)" class="m-1 cursor-pointer" icon="pi pi-trash" value="Eliminar"
                  severity="danger"></Tag>
              </span>
            </li>
          </template>
          <template v-if="!loadingCompanyRecruiters && currentRecruiters.length == 0">
            <p class="px-2 py-3 text-center text-lg">
              {{ translate('no recruiter found', 'ucfirst') }}!
            </p>
          </template>
        </ul>
      </div>

    </section>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Button from "primevue/button"; 
import Tag from "primevue/tag";
import { useToast } from 'primevue/usetoast';
import { translate } from "@/composables/translate";
import { showToast } from "@/composables/toast";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import requestHandler from "@/composables/requestHandler";
import { sleep } from "@/composables/sleep";
import AutoComplete from "primevue/autocomplete";
import utils from "@/composables/utils";
import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
import recruiterData from "@/composables/recruiterData";

// ############ Declaração de variáveis ############

// let recruiter_id = ref(0);
const currentRecruiters = ref([])
const loading = ref(false);
const toast = useToast();
const loadingCompanyRecruiters = ref(true);

let recruiterTerm = ref('');
let recruiterName = ref([]);
let recruiterList = ref([]);

// ############ Criação de Métodos/Funções ############
 

// Remover recrutador
async function removeCompanyRecruiter(id) {
  if(['',null,undefined].includes(id)){
    showToast(toast, translate('no recruiter selected', 'ucfirst'), 'error');
    return;
  }
  loading.value = true;
  const response = await requestHandler.executeRequest("/api/company/removerecruiter", {company_recruiter_id: id}, 'delete'); 
  loading.value = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  showToast(toast, response.message);
  getCurrentRecruiters();
}

// Busca os recrutadores da empresa
async function getCurrentRecruiters() {
  loadingCompanyRecruiters.value = true;
  const response = await requestHandler.executeRequest('api/company/recruiter/index', {}, 'get');
  loadingCompanyRecruiters.value = false;
  if (!response.success) {
    showToast(toast, response.message, 'error');
    return;
  }
  else {
    let values = [];
    for(let recruiterObj of response.data.data){
      values.push(recruiterData.readRecruiterPhoto(recruiterObj));
    }
    currentRecruiters.value = values;
  }
}

// Lista Todos recrutadores pra empresa escolher
async function getRecruiters(event) {
  sleep(500)
  recruiterTerm.value = event.query;
  if(recruiterTerm.value == '' || recruiterTerm.value.length < 3)
    return;
  const response = await requestHandler.executeRequest("api/company/findrecruiter", {term: recruiterTerm.value}, 'get');
  let values = [];
  let responseAsArray = utils.parseToArray(response.data.data);
  for(let recruiter of responseAsArray){
    values.push({name: recruiter['person_username'], code: recruiter['recruiter_id']});
  }
  recruiterList.value = values;
  recruiterTerm.value = '';
}


//  Envia o convite para o recrutador
async function sendInvitationToRecruiter() {
  let recruiterData = recruiterName.value;
  if(recruiterData.length < 1 || ['',null,undefined].includes(recruiterData.code)){
    showToast(toast, translate('no recruiter selected', 'ucfirst'), 'error');
    return;
  }
  loading.value = true;
  const response = await requestHandler.executeRequest("/api/company/inviterecruiter", {recruiter_id: recruiterData.code}); 
  loading.value = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  recruiterName.value = [];
  showToast(toast, response.message);
  getCurrentRecruiters();
}

onMounted(() => {
  getCurrentRecruiters();
  handleAutoCompleteElements();
})

async function handleAutoCompleteElements(){
  let tries = 0;
  let autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
  while(autocompleteEls.length < 1){
      autocompleteEls = document.querySelectorAll('.autocomplete-w-full');
      tries++;
      await sleep(50);
      if(tries == 30){
        break;
    }
  }
  if(autocompleteEls.length < 1)
    return;
  autocompleteEls.forEach(el => {
  if(el.style.width == '')
    el.style.setProperty('width', '100%', 'important');
      const ulEl = el.querySelector('ul');
      if(ulEl){
        ulEl.style.setProperty('width', '100%', 'important');
        // ulEl.style.setProperty('border-radius', '9999px', 'important');
        let inputEl = ulEl.querySelector('input');
      if(inputEl){
        inputEl.style.setProperty('width', '100%', 'important');
        inputEl.style.setProperty('outline', 'unset', 'important');
      }
    }
  });
}

function getSeverityOfRecuriter(status = 'active'){
  let severity = 'active';
  switch(status){
    case 'active':
      severity = 'active';
    break;
    case 'invited':
      severity = 'secondary';
    break;
    case 'refused':
      severity = 'danger';
    break;
  }
  return severity;
}
</script>