<template>
  <ProfileChooseComponent
    v-if="profileChoose.show"
    :action="'switchAccount'"
    @profileChosen="switchAccount(true)"
  />
  <LoadingDialog :visible="loading" />
  <div id="dashboardHeaderId">
    <!-- Menu -->
    <div
      class="flex flex-wrap3 justify-between items-center z-50 px-4 4md:px-5 xl:px-7 py-5 bg-white"
    >
      <span
        class="w-5/6 md:w-56 lg:w-80 flex items-center px-3 rounded-full border border-iron"
        :style="[undefined, null, 0].includes(env('APP_BETA')) ? '' : {opacity: 0}"
      >
        <i class="pi pi-search text-gray-400" />
        <InputText
          v-model="mainSearch"
          :placeholder="translate('what do you seek?', 'ucfirst')"
          icon="pi pi-search"
          id="text"
          rounded
          class="w-full py-1.5 ml-3 outline-none font-normal rounded-full"
        />
      </span>
      <Button
        type="button"
        icon="pi pi-bars"
        @click="toggle"
        style="font-size: 1.8rem;"
        aria-haspopup="true"
        aria-controls="home_user_submenu"
        id="mobile-opt"
        class="md:hidden"
      />


      <!--  -->
      <div
        class="hidden md:flex justify-between md:justify-normal items-center flex-wrap mt-3.5 md:mt-0 gap-x-4 gap-y-4 md:gap-y-0"
      >
        <RouterLink
          v-if="!['','professional','recruiter'].includes(user.loggedAs)"
          :to="`/dashboard/${user.loggedAs}/${user.slug}/post-job`"
          class="flex items-center pl-1.5 pr-3 pt-1.5 pb-1.5 rounded-full text-white font-medium bg-sea-serpent"
        >
          <i class="pi pi-plus bg-white rounded-full text-gray-500 p-1 text-sm mr-1"></i>
          <span class="text-sm text-white">{{ translate('new job', 'ucfirst') }}</span>
        </RouterLink>

        <RouterLink
          :to="`/`"
          class="hidden xl:flex items-center pl-1.5 pr-3 pt-1.5 pb-1.5 rounded-full font-medium border border-iron text-oxford-blue"
        >
          <i class="pi pi-globe bg-white rounded-full text-gray-500 p-1 text-sm mr-1"></i>
          <span class="text-sm">{{ translate('go back to website', 'ucfirst') }}</span>
        </RouterLink>

        <i v-if="[undefined, null, 0].includes(env('APP_BETA'))" class="pi pi-bell mr-3 relative" style="font-size: 1.4rem; color: #3c496c"
          ><span
            class="bg-red-800 text-white text-[13px] font-semibold rounded-full pt-[0.2rem] pb-[0.3rem] px-[0.3rem] absolute -right-3 -top-3"
            >46</span
          >
        </i>

        <span class="flex items-center ml-auto">
          <img
            :src="store.getters.getProfileImage ? store.getters.getProfileImage : require('@/assets/images/avatar.png')"
            class="rounded-full border-2 border-white w-10 h-10 mr-2"
          />
          <p class="text-sm font-semibold">{{ user.name }}</p>
          <Button
            type="button"
            icon="pi pi-chevron-down"
            @click="toggle"
            aria-haspopup="true"
            aria-controls="home_user_submenu"
          />
          
          <!-- <Avatar 
            :image="store.getters.getProfileImage ? store.getters.getProfileImage : require('@/assets/images/avatar.png')" 
            class="rounded-full w-10 h-10"
            shape="circle" 
          /> -->

          <Menu ref="menu" id="home_user_submenu" :model="items" :popup="true">
            <template #item="{ item, props }">
              <div v-if="item.profile.includes(store.getters.getLoggedAs) && (item.display == undefined || item.display == true)">
                <RouterLink
                  v-if="item.route"
                  v-slot="{ href, navigate }"
                  :to="item.route"
                  custom
                >
                  <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                    <span :class="item.icon" />
                    <span class="ml-2">{{ item.label }}</span>
                  </a>
                </RouterLink>
                <a
                  v-else
                  v-ripple
                  :href="item.url"
                  :target="item.target"
                  v-bind="props.action"
                  @click.prevent="callMethod(item.action)"
                >
                  <span :class="item.icon" />
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </div>
            </template>
          </Menu>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import { RouterLink } from "vue-router";
import { computed, onMounted, reactive, ref } from "vue";
import authService from "@/composables/authUser";
import { showToast } from "@/composables/toast";
import { useToast } from 'primevue/usetoast';
import requestService from "@/composables/requestHandler";
import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
import { translate } from "@/composables/translate";
import store from "@/store";
import ProfileChooseComponent from "@/components/ProfileChooseComponent";
import { env } from "@/composables/config";

const toast = useToast();
const profileChoose = reactive({
  show: false,
  profileName: null
});

onMounted(() =>{
  document.querySelector('#mobile-opt').querySelector('.pi-bars').style.fontSize = '1.8rem';
});

const loading = ref(false);
let mainSearch = ref("");

let user = reactive({
  username: computed(() => store.state.user.name),
  usertype: computed(() => store.state.user.userType),
  loggedAs: computed(() => store.state.user.loggedAs),
  slug:     computed(() => store.state.user.slug),
  accounts: computed(() => store.state.user.accounts)
});

const menu = ref();
const items = ref([
  {
    label: "",
    items: [
      {
        label: translate('go back to website', 'ucfirst'),
        icon: "pi pi-home",
        route: `/`,
        profile: ['company','recruiter','professional',null]
      },
      {
        label: translate('publish job offer', 'ucfirst'),
        icon: "pi pi-file-edit",
        route: `/dashboard/${user.loggedAs}/${user.slug}/post-job`,
        profile: ['company']
      },
      {
        label: translate('create profile', 'ucfirst'),
        icon: "pi pi-user-plus",
        profile: ['company','recruiter','professional'],
        route: `/dashboard/${user.slug}/createprofile`,
        display: store.getters.getProfileTypes.length != 3 ? true : false 
      },
      {
        label: translate('edit profile', 'ucfirst'),
        icon: "pi pi-user-edit",
        route: `/dashboard/${user.loggedAs}/${user.slug}/profile`,
        profile: ['company']
      },
      {
        label: translate('switch account', 'ucfirst'),
        icon: "pi pi-sync",
        action: 'switchAccount',
        profile: ['company','recruiter','professional'],
        display: store.getters.getLoggedUserProfiles.length > 1 ? true : false 
      },
      {
        label: translate('end session', 'ucfirst'),
        icon: "pi pi-sign-out",
        action: 'logout',
        profile: ['company','recruiter','professional',null]
      },
    ],
  },
]);

const toggle = (event) => {
  menu.value.toggle(event);
};

function callMethod(methodName = ''){
  switch(methodName){
    case 'logout':
      logout();
      break;
    case 'switchAccount':
      switchAccount();
      break;
  }
}

async function switchAccount(close = false){
  if(!close && profileChoose.profileName == null){
    profileChoose.show = true;
    return;
  }
  profileChoose.show = false;
  showToast(toast, translate('profile changed', 'ucfrist'));
}

async function logout(){
  loading.value = true;
  const response = await authService.logout();
  if(!response['success']){
    showToast(toast, response['message'], 'error');
  }else{
    store.dispatch('saveSessionMessage', {message: translate('logged out with success', 'ucfirst'), messageType: 'success'});
    requestService.redirect('/login');
  }
  loading.value = false;
}
</script>

<style scoped>
#hamburguer-icon {
  display: flex;
}

@media (min-width: 768px) {
  #hamburguer-icon {
    display: none;
  }
}
</style>
