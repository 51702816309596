<template>
  <div>
    <LoadingDialog :visible="loading" />

    <div 
      v-if="showAcceptCookiesDialog" 
      style="z-index: 100000; margin-bottom: 5%;"
      class="fixed bottom-0 w-full md:w-2/5 rounded p-3"
    >
      <div class="mx-auto bg-white py-5 px-2 rounded border-iron shadow-md">
        <img src="@/assets/cookies.svg" class="h-20 mb-2 px-2">
        <p class="px-2 text-xl">
          {{ `${translate("by clicking 'Accept all cookies', you agree Jobifull can store cookies on your device and use information in accordance with our Cookie Policy", 'ucfirst')}.` }}
        </p>
        <p class="px-2 my-2">
          {{ `${translate('note: If your do not accept our cookies, you will not be able to login', 'ucfirst')}. ${translate('know more at our', 'ucfirst')}` }} 
          <RouterLink to="/cookies-policy" class="underline">
            {{ translate('cookies policy', 'capitalize') }}.
          </RouterLink>
        </p>
        <div class="flex justify-between">
          <Button 
            :label="translate('do not accept', 'ucfirst')"
            class="w-full md:w-3/5 md:me-2 py-2 px-5 mt-4 text-white rounded bg-red-600"
            @click="handleCookies(false)"
          />
          <Button 
            :label="translate('accept all', 'ucfirst')"
            class="w-full md:w-3/5 py-2 px-5 mt-4 text-white rounded bg-sea-serpent"
            @click="handleCookies(true)"
          />
        </div>
      </div>
    </div>

    <div class="px-5 xs:px-10 md:px-12">
      <!-- Dialog de recuperação de conta -->
      <Dialog
        v-model:visible="show_modal"
        modal
        :style="{ width: '35rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        :draggable="false"
        class="relative bg-white rounded-lg"
      >
        <h1 class="text-2xl font-bold text-center text-oxford-blue">{{ translate('recover password', 'ucfirst') }}</h1>
        <p class="text-center">{{ translate('a link will be sent to your email for a password reset', 'ucfirst') }}</p>

        <form @submit.prevent="forgotPassword" class="flex flex-col items-center my-6">
          <InputText
            v-model.trim="forgot.email"
            :placeholder="translate('email', 'ucfirst')"
            id="email"
            aria-describedby="email-help"
            class="w-full md:w-3/4 mb-1.5 py-2 px-3 rounded-lg border border-color-iron"
          />
          <small id="email-help">{{ translate('enter the email for the password reset', 'ucfirst') }}.</small>

          <Button
            v-if="forgot.waitForSending == 0"
            type="submit"
            :label="translate('request a link for password reset', 'ucfirst')"
            class="w-full md:w-3/4 py-2 px-5 mt-4 text-white rounded-lg bg-sea-serpent"
            :loading="forgot.sendingEmail"
          />

          <div class="w-full mt-5 mb-5 rounded-xl text-center p-3" v-if="forgot.waitForSending > 0">
              <LoaderComponent />
              <p class="mt-3">
                {{ translate('wait for', 'ucfirst') }} {{ forgot.waitForSending }} {{ translate('before requesting new email') }}
              </p>
          </div>

          <div v-if="forgot.emailSent" class="alert alert-success mt-5 text-center border-top">
            <p>
              {{ translate('email sent to', 'ucfirst') }} {{ forgot.email }}!
            </p>
            <small>{{ translate('don\'t forget to check your email\'s junk or spam folder', 'ucfirst') }}.</small>
          </div>
        </form>
      </Dialog>

      <EmailVerificationComponent 
        :visibled="emailVerification.show" 
        v-if="emailVerification.show"
        :email="user.email"
      />

      <ProfileChooseComponent
        v-if="profileChoose.show"
      />

      <section
        class="w-full lg:w-4/5 xl:w-2/3 mx-auto my-32 flex rounded-lg bg-white shadow-lg overflow-hidden"
      >
        <div class="w-full md:w-[60%] xl:w-2/4 p-4 md:p-8">
          <h1 class="text-4xl font-bold text-center mt-5 mb-8 text-oxford-blue">{{ translate('login', 'ucfirst') }}</h1>
          <form @submit.prevent="login">
            <div class="flex flex-col">
              <label for="email" class="pb-1 text-lg font-semibold">{{ translate('email', 'ucfirst') }}</label>
              <InputText
                v-model.trim="user.email"
                :placeholder="translate('your email', 'ucfirst')"
                id="email"
                class="w-full py-2 px-3 rounded-lg border border-color-iron"
                :required="user.user"
              />
            </div>
            <div class="flex flex-col mt-5">
              <label for="password" class="pb-1 text-lg font-semibold">{{ translate('password', 'ucfirst') }}</label>
              <Password
                v-model="user.password"
                :placeholder="translate('your password', 'ucfirst')"
                :feedback="false"
                toggleMask
                id="password"
                inputClass="w-full py-2 px-3 rounded-lg border border-color-iron"
                :required="user.user"
              />
            </div>

            <div class="flex justify-between items-center py-3 font-normal">
              <p class="flex items-center gap-2">
                <Checkbox
                  v-model="user.remember"
                  :binary="true"
                  inputId="remember"
                  class="rounded-md border"
                />
                <label for="remember">{{ translate('remember password', 'ucfirst') }}</label>
              </p>
              <p @click="show_modal = true" class="cursor-pointer text-sea-serpent">
                {{ translate('forgot your password?', 'ucfirst') }}
              </p>
            </div>
            <Button
              :loading="request_status.loading"
              type="submit"
              :label="translate('enter', 'ucfirst')"
              class="w-full py-2 px-5 text-white rounded-lg bg-sea-serpent"
            />
          </form>
          <Divider class="w-full" align="center" type="solid" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
            <b>{{ translate('or', 'upper') }}</b>
          </Divider>
          <div class="flex justify-center gap-4" v-if="[undefined, null, 0].includes(env('APP_BETA'))">
            <Button :loading="request_status.loading">
              <span
                class="flex justify-center items-center rounded-full bg-sea-serpent w-9 h-9"
              >
                <i class="pi pi-google" style="font-size: 1.3rem; color: #fff"></i>
              </span>
            </Button>
            <Button :loading="request_status.loading">
              <span
                class="flex justify-center items-center rounded-full bg-sea-serpent w-9 h-9"
              >
                <i class="pi pi-facebook" style="font-size: 1.3rem; color: #fff"></i>
              </span>
            </Button>
            <Button :loading="request_status.loading">
              <span
                class="flex justify-center items-center rounded-full bg-sea-serpent w-9 h-9"
              >
                <i class="pi pi-instagram" style="font-size: 1.3rem; color: #fff"></i>
              </span>
            </Button>
          </div>
          <p class="text-center my-5">
            {{ translate('new member?', 'ucfirst') }}
            <RouterLink to="/register" class="font-medium text-sea-serpent"
              >{{ translate('register here', 'ucfirst') }}</RouterLink
            >
          </p>
        </div>
        <div class="hidden md:flex md:w-2/5 xl:w-2/4 box-login-right-side"></div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { RouterLink } from "vue-router";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Checkbox from "primevue/checkbox"; 
import requestHandler from "@/composables/requestHandler";
import authUser from "@/composables/authUser";
import store from "@/store";
import EmailVerificationComponent from "@/components/EmailVerificationComponent";
import ProfileChooseComponent from "@/components/ProfileChooseComponent";
import Cookies from "js-cookie";
import { decrypte, encrypte } from "@/composables/crypto";
import { showToast } from "@/composables/toast";
import { useToast } from 'primevue/usetoast';
import { translate } from "@/composables/translate";
import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import { sleep } from "@/composables/sleep";
import { env } from "@/composables/config";
import cookiesHandler from "@/composables/cookiesHandler";

// ############ Declaração de variáveis ############

const show_modal = ref(false);
const emailVerification = reactive({
  show: false
});
const profileChoose = reactive({
  show: false
});
const request_status = reactive({
  loading: false
});

const loading = ref(false);

const user = reactive({
  email: "",
  password: "",
  remember: false,
  required: true,
  personType: ''
});

const forgot = ref({
  email: "",
  message: "",
  sendingEmail: false,
  emailSent: false,
  waitForSending: 0
});
const toast = useToast();

let showAcceptCookiesDialog = ref(false);
onMounted( function(){
  // if(!cookiesHandler.hasAcceptedCookies()){
  //   showAcceptCookiesDialog.value = true;
  //   return;
  // }
  if(store.getters.hasSessionManage){
    const sessionMessageData = store.getters.getSessionMessage;
    showToast(toast, sessionMessageData.message, sessionMessageData.type);
    store.dispatch('cleanSessionMessage');
  }
  if(store.getters.isLoggedIn){
    requestHandler.redirectByProfile();
  }
  if(Cookies.get('aut_data')){
    const authData = decrypte(Cookies.get('aut_data')).split('--divider--');
    if(authData.length == 2){
      loading.value = true;
      user.email = authData[0];
      user.password = authData[1];
      user.remember = true;
      user.personType = Cookies.get('last_login_as') != '' ? decrypte(Cookies.get('last_login_as')) : '';
      login();
    }
  }
});

// ############ Chamando componentes e composables ############

// ############ Criação de Métodos/Funções ############

/**
 * Performs login and check if there's a profile, if there is, open modal requesting used input and redirect to dashboard type!
 * Note: On no profile, redirects to '/dashboard/profile'
 */
async function login() {
  // if(!cookiesHandler.hasAcceptedCookies()){
  //   showToast(toast, translate('you must accept our cookies to login'), 'error');
  //   showAcceptCookiesDialog.value = true;
  //   return;
  // }
  if(user.email == ''){
    showToast(toast, translate('please enter an email'), 'error');
    loading.value = false;
    return
  }
  if(user.password == ''){
    showToast(toast, translate('please enter a password'), 'error');
    loading.value = false;
    return
  }
  loading.value = true;
  request_status.loading = true;
  let response = await authUser.login({email: user.email, password: user.password});
  loading.value = true;
  request_status.loading = false;
  if(!response.success){
    Cookies.remove('aut_data');
    showToast(toast, response.message, 'error');
    loading.value = false;
    
    return;
  }
  if(user.remember){
    Cookies.set('aut_data', encrypte(`${user.email}--divider--${user.password}`));
  }else{
    Cookies.remove('aut_data');
  }
  if(response.data.personType !== '')
    store.dispatch('saveLoggedAs', response.data.personType);
  showToast(toast, Cookies.get('aut_data') ? translate('be welcome', 'ucfirst') : translate('login completed'));
  store.dispatch('saveLoginInformation', response.data);
  if(response.data.person.accepted_cookies != null)
    store.dispatch('saveCookiesAccepted', response.data.person.accepted_cookies);
  if(Cookies.get('last_login_as') !== undefined && Cookies.get('last_login_as') !== '' && authUser.profileIsValid(decrypte(Cookies.get('last_login_as')))){
    store.commit('setLoggedAs', decrypte(Cookies.get('last_login_as')));
    await requestHandler.redirect(`/dashboard/${decrypte(Cookies.get('last_login_as'))}/${store.state.user.slug}`);
  }else{
    let userProfileType = store.getters.hasOnlyOneProfile;
    if(userProfileType){
      store.commit('setLoggedAs', userProfileType);
      await requestHandler.redirect(`/dashboard/${userProfileType}/${store.state.user.slug}`);
    }
    profileChoose.show = true;
  }
}

async function forgotPassword() {
  forgot.value.emailSent = false;
  forgot.value.sendingEmail = true;
  const response = await authUser.forgotPassword(forgot.value.email);
  forgot.value.sendingEmail = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  forgot.value.emailSent = true;
  startCounting();
}

async function startCounting(){
  let awaitInSeconds = 60;
  let tracker = awaitInSeconds;
  for(let i = 0; i < awaitInSeconds; i++){
    await sleep(1000);
    tracker--;
    forgot.value.waitForSending = tracker;
  }
  forgot.value.waitForSending = 0;
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############

async function handleCookies(accepted = true){
  if(accepted){
    cookiesHandler.saveCookieAcecptance();
  }else{
    cookiesHandler.removeCookieAcceptance();
    await store.dispatch('saveSessionMessage', {message: translate('you must accept our cookies to login'), messageType: 'error'});
    await requestHandler.redirect('/');
  }
  showAcceptCookiesDialog.value = false;
}
</script>

<style scoped>
.box-login-right-side {
  background-image: url("@/assets/images/img-login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
