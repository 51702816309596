<template>
  <div>
    <Dialog
      v-model:visible="languagesData.show"
      modal
      :header="translate('choose your language', 'ucfirst')"
      :style="{ width: '40rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :draggable="false"
    >
      <form>
        <InputText
          v-model="languagesData.value"
          @input="filterLanguages()"
          class="w-full mb-1.5 py-2 px-3 rounded-lg border border-color-iron"
        />

        <div class="w-full py-5">
          <ul class="flex flex-wrap gap-6">
            <template
              v-for="(langIso, langId) of languagesData.filtered" 
              :key="langId"
            >
              <li v-if="langIso[store.getters.getUserLang] != store.getters.getUserLangName && officialLanguages.includes(langIso['isoCode'])">
                <Button class="btn-register" @click="selectLanguage(langIso['llangue_id'])">
                  <img class="w-8 h-8 me-2" :src="getOnPublic(`data/langs/${langIso['isoCode']}.svg`)">
                  {{ ucfirst(langIso[store.getters.getUserLang] !== null ? langIso[store.getters.getUserLang] : langIso['en']) }}
                </Button>
              </li>
            </template>
          </ul>
        </div>
      </form>
    </Dialog>



    <footer id="application-footer" class="w-full md:fixed bottom-0 left-0 z-50 footer">
      <p class="order-2 xl:order-none xl:text-sm">
        &copy; {{  env('APP_RELEASE_DATE') }} - {{ translate('all rights reserved to', 'ucfirst') }}
        <span class="font-bold text-oxford-blue">{{ env('APP_NAME') }}</span>
        | {{ translate('version', 'ucfirst') }} {{ [1,'1'].includes(env('APP_BETA')) ? 'BETA' : '' }} {{ env('APP_VERSION') }}
      </p>
      <ul
        class="flex flex-col md:flex-row xl:order-none space-y-1 md:space-y-0 md:space-x-6 lg:space-x-5"
      >
        <li><RouterLink to="/about">{{ translate('about us', 'capitalize') }}</RouterLink></li>
        <li v-if="[undefined, null, 0].includes(env('APP_BETA'))"><RouterLink to="/faq">{{ translate('frequent questions', 'capitalize') }}</RouterLink></li>
        <li><RouterLink to="/terms">{{ translate('terms of service', 'capitalize') }}</RouterLink></li>
        <li><RouterLink to="/privacy-policy">{{ translate('policy privacy', 'capitalize') }}</RouterLink></li>
      </ul>
      <p
        class="flex items-center font-bold space-x-2 mt-2 mb-4 md:my-0 lg:ml-auto xl:ml-0 cursor-pointer"
        @click="languagesData.show = true"
      >
        <img class="w-8 h-8" :src="getOnPublic(`data/langs/${store.getters.getUserLang}.svg`)">
        <span>{{ ucfirst(store.getters.getUserLangName) }}</span>
        <i class="pi pi-chevron-up cursor-pointer"></i>
      </p>
    </footer>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { RouterLink } from "vue-router";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { translate } from "@/composables/translate";
import store from "@/store";
import Button from "primevue/button";
import languageService from "@/composables/languageData";
import { env } from "@/composables/config";
import Cookies from "js-cookie";
import { encrypte } from "@/composables/crypto";
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";
import { ucfirst } from "@/composables/textUtils";
import { sleep } from "@/composables/sleep";
import routeHandler from "@/composables/routeHandler";
import { getOnPublic } from "@/composables/imageChecker";

const toast = useToast();

let languagesData = reactive({
  original: [],
  filtered: [],
  ids: {},
  enInfo: {},
  value: '',
  show: false
});

let officialLanguages = ref([]);
onMounted(function(){
  officialLanguages.value = languageService.getOfficialLanguagesIsoCodes();
  const langs = languageService.getAllLanguages();
  languagesData.original = langs;
  languagesData.filtered = langs;
  languagesData.ids = {};
  for(let isoCode in langs){
    languagesData.ids[langs[isoCode]['llangue_id']] = {isoCode: isoCode, langs: langs[isoCode]};
    if(isoCode == 'en')
      languagesData.enInfo = {isoCode: isoCode, langs: langs[isoCode]};
  }
  execTawkTo();
})

async function execTawkTo(){
  var Tawk_API = Tawk_API || {};
  let scriptEl = document.createElement("script"),
  scriptEl2 = document.getElementsByTagName("script")[0];
  scriptEl.async = true;
  scriptEl.src = `https://embed.tawk.to/${env('APP_TAWKTO_SECRET')}/${env('APP_TAWKTO_ID')}`;
  scriptEl.charset = "UTF-8";
  scriptEl.setAttribute("crossorigin", "*");
  scriptEl2.parentNode.insertBefore(scriptEl, scriptEl2);

  let executed = false;
  let tries = 0;
  let footerEl = document.querySelector('#application-footer');
  if(footerEl == null)
    return;
  while(!executed || tries > 600){
    tries++;
    await sleep(200);
    let container = document.querySelector('.widget-visible');
    if([null,undefined].includes(container))
      continue;
    let tawkToEl = container.querySelector('iframe');
    if(![null,undefined].includes(tawkToEl)){
      let height = footerEl.offsetHeight;
      let finalHeight = height + ((height / 100) * 20);
      tawkToEl.style.setProperty('bottom', `${finalHeight}px`, 'important');
      executed = true;
    }
  }
}

function filterLanguages(){
  languagesData.filtered = languagesData.original;
  if(languagesData.value == ''){
    return;
  }
  let arr = [];
  let filteredArray = Object.entries(languagesData.filtered);
  for(let lang of filteredArray){
    let translation = languagesData.filtered[lang[0]][store.getters.getUserLang] !== null ? languagesData.filtered[lang[0]][store.getters.getUserLang] : languagesData.filtered[lang[0]]['en'];
    if(translation.includes(languagesData.value))
      arr.push(languagesData.filtered[lang[0]]);
  }
  languagesData.filtered = arr;
}

function selectLanguage(langId){
  let langData = languagesData.ids[langId];
  if(langData === undefined || langData.langs === undefined)
    langData = languagesData.enInfo;
  let langName = langData.langs[langData.isoCode] === null ? langData.langs['en'] : langData.langs[langData.isoCode];
  store.dispatch('changeLanguage', {langIso: langData.langs.isoCode, langName: langName});
  Cookies.set('userLang', encrypte(langData.isoCode));
  Cookies.set('langName', encrypte(langName));
  languagesData.show = false;
  languagesData.value = '';
  languagesData.filtered = languagesData.original;
  showToast(toast, translate('language changed', 'ucfirst'), 'success');
  store.dispatch('cleanDataChest', {});
  store.dispatch('cleanComboChest', {});
  routeHandler.reloadThisPage();
  emitReload();
}

const emit = defineEmits(['rebuildApplication']);
function emitReload() {
  emit('rebuildApplication');
}

</script>

<style scoped></style>
