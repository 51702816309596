<template>
  <div class="">
    <div class="flex flex-col gap-y-10 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8">
      <!-- Seção visão geral estatísticas -->
      <section v-if="[undefined, null, 0].includes(env('APP_BETA'))">
        <ul class="flex flex-wrap justify-between gap-y-4 md:gap-x-1f lg:gap-x-2">
          <li
            v-for="(overview, index) in dataOverview"
            :key="index"
            class="w-full md:w-[24.4%] lg:w-[24%] md:h-24 flex justify-between items-center bg-white shadow-lg p-3 md:px-5 rounded-md border-t-2 border-sea-serpent hover:text-white hover:bg-sea-serpent"
          >
            <span>
              <h1
                class="mb-1 text-xl xl:text-3xl font-semibold text-oxford-blue hover:text-white"
              >
                {{ overview.number }}
              </h1>
              <RouterLink
                :to="`/dashboard/${username}/settings`"
                class="text-sm font-normal"
                >{{ overview.name }}
              </RouterLink>
            </span>
            <i class="pi pi-briefcase" style="font-size: 1.5rem"></i>
          </li>
        </ul>
      </section>

      <!-- Seção gráficos -->
      <DashboardCharts v-if="[undefined, null, 0].includes(env('APP_BETA'))" />

      <!-- Seção vagas recentes -->
      <DataTables :limit="5" :seeMoreLink="true" />

      <!-- Seção Candidatos Recomendados -->
      <ApplicantsRecomendation v-if="[undefined, null, 0].includes(env('APP_BETA'))" />
      
      <!-- Donations section -->
      <DonationLinkComponent />
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { RouterLink } from "vue-router";
import { useStore } from "vuex";

import ApplicantsRecomendation from "@/components/Dashboard/Company/ApplicantsRecomendation.vue";
import DataTables from "@/components/Dashboard/Company/DataTables";
import DashboardCharts from "@/components/Dashboard/DashboardCharts.vue";
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";
import { env } from "@/composables/config";
import DonationLinkComponent from "@/components/DonationLinkComponent"
import routeHandler from "@/composables/routeHandler";

const toast = useToast();
const store = useStore();
let username = store.getters.getUserName;

onMounted(() => {
  routeHandler.handleRedirects();
  if(store.getters.hasSessionManage){
    const sessionMessageData = store.getters.getSessionMessage;
    showToast(toast, sessionMessageData.message, sessionMessageData.type);
    store.dispatch('cleanSessionMessage');
  }
});

</script>