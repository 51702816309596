<template>
  <div>
    <div class="grid grid-cols-5 xl:grid-cols-3 gap-4 xl:gap-x-6 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8">
      <!-- Seção dos campos formulário -->
      <div class="col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5">
        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
          <h1 class="mb-6 text-lg font-semibold">{{ translate('Professional information', 'ucfirst') }}</h1>

          <form @submit.prevent="updateprofessionalProfile" class="grid grid-cols-2 gap-x-4 gap-y-8 text-base font-normal">
            <span class="col-span-2 md:col-span-1 p-float-label">
              <InputText v-model.trim="professionalProfile.professional_firstname" type="text" id="text"
                class="w-full px-3 py-2 rounded-full border border-iron" />
              <label for="value">{{ translate('first name', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 md:col-span-1 p-float-label">
              <InputText v-model.trim="professionalProfile.professional_lastname" type="text" id="text"
                class="w-full px-3 py-2 rounded-full border border-iron" />
              <label for="value">{{ translate('last name', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 p-float-label">
              <InputText v-model.trim="professionalProfile.professional_title" type="text" id="name"
                class="w-full px-3 py-2 rounded-full border border-iron" />
              <label for="name">{{ translate('title', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 md:col-span-1  p-float-label">
              <InputText v-model.trim="professionalProfile.professional_email" type="email" id="email"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="email">{{ translate('email', 'ucfirst') }}</label>
            </span>
            <span class="w-full md:w-1/2 md:pr-3 p-float-label">
                <LoaderComponent v-if="comboData.loading.ddi" />
                <Dropdown 
                  v-if="!comboData.loading.ddi"
                  id="ddi"
                  v-model="companyProfile.company_ddi" 
                  :options="comboData.data.ddi"
                  filter 
                  optionLabel="name" 
                  :inputClass="'text-oxford-blue font-semibold'"
                  :placeholder="translate('ddi', 'upper')" 
                  class="w-full rounded-full border border-iron text-oxford-blue"
                >
                  <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                          <img 
                            :alt="slotProps.value.label" 
                            :src="getOnPublic(slotProps.value.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.value.name}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.value.ddi }} {{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps">
                      <div class="flex align-items-center">
                          <img 
                            :alt="slotProps.option.label" 
                            :src="getOnPublic(slotProps.option.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.option.code}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.option.ddi }} {{ slotProps.option.name }}</div>
                      </div>
                  </template>
                </Dropdown>
                <label v-if="!comboData.loading.ddi" id="label-company_id" for="ddi">{{ translate('ddi', 'upper') }}</label>
              </span>
            <span class="col-span-2 lg:col-span-1 w-full p-float-label">
              <InputNumber :modelValue="professionalProfile.professional_phone" :useGrouping="false" inputId="phone1" :unstyled="true"
                inputClass="w-full px-3 py-2 rounded-full outline-none border border-iron" />
              <label>{{ translate('Phone', 'ucfirst') }}</label>
            </span>  
            <span class="col-span-2">
              <Button type="submit" :label="translate('save', 'ucfirst')" class="py-2 px-4 float-right text-white rounded-md bg-sea-serpent w-full md:w-auto" />
            </span>
          </form>
        </section>

        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
          <h1 class="mb-6 text-lg font-semibold">{{ translate('user information', 'ucfirst') }}</h1>
          <!-- Informações do usuário logado -->
          <form class="grid grid-cols-2 gap-x-4 gap-y-8 text-base font-normal">
            <span class="col-span-2 md:col-span-1 p-float-label">
              <InputText v-model.trim="personProfile.person_username" type="text" id="name"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="name">{{ translate('user name', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 md:col-span-1  p-float-label">
              <InputText v-model.trim="personProfile.person_email" type="email" id="email"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="email">{{ translate('email', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 w-full p-float-label">
              <LoaderComponent v-if="comboData.loading.ddi" />
                <Dropdown 
                  v-if="!comboData.loading.ddi"
                  id="ddi"
                  v-model="personProfile.person_ddi" 
                  :options="comboData.data.ddi"
                  filter 
                  optionLabel="name" 
                  :inputClass="'text-oxford-blue font-semibold'"
                  :placeholder="translate('ddi', 'upper')" 
                  class="w-full rounded-full border border-iron text-oxford-blue"
                >
                  <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                          <img 
                            :alt="slotProps.value.label" 
                            :src="getOnPublic(slotProps.value.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.value.name}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.value.ddi }} {{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps">
                      <div class="flex align-items-center">
                          <img 
                            :alt="slotProps.option.label" 
                            :src="getOnPublic(slotProps.option.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.option.code}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.option.ddi }} {{ slotProps.option.name }}</div>
                      </div>
                  </template>
              </Dropdown>
              <label v-if="!comboData.loading.ddi" id="label-person_ddi" for="ddi">{{ translate('ddi', 'upper') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <InputText 
                  v-model="personProfile.person_phone" 
                  id="person_phone" 
                  class="w-full flex px-3 py-2 rounded-full outline-none border border-iron text-oxford-blue font-semibold"
                />
              <label for="person_phone" id="label-person_phone" class="pl-3">{{ translate('phone', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <LoaderComponent v-if="comboData.loading.language" />
              <Dropdown
                v-model="personProfile.person_langue"
                :options="comboData.data.language"
                :loadind="comboData.loading.language"
                optionLabel="name"
                :placeholder="translate('select a language', 'ucfirst')"
                class="w-full rounded-full border border-iron text-oxford-blue"
                :inputClass="'text-oxford-blue font-semibold'"
                required
                filter
                id="language"
              />
              <label v-if="!comboData.loading.language" id="label-person_lang" for="language">{{ translate('language', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <LoaderComponent v-if="comboData.loading.currency" />
              <Dropdown
                v-model="personProfile.currency"
                :options="comboData.data.currency"
                :loadind="comboData.loading.currency"
                optionLabel="name"
                :placeholder="translate('select a currency', 'ucfirst')"
                class="w-full rounded-full border border-iron text-oxford-blue"
                :inputClass="'text-oxford-blue font-semibold'"
                required
                filter
                id="currency"
              />
              <label v-if="!comboData.loading.currency" id="label-currency" for="currency">{{ translate('currency', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2">
              <Button @click.prevent="updatePersonProfile()" :label="translate('save', 'ucfirst')" class="py-2 px-4 float-right text-white rounded-md bg-sea-serpent w-full md:w-auto" />
            </span>
          </form>
        </section>
      </div>

      <!-- Barra lateral direita -->
      <aside
        class="col-span-5 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]">
        <Panel :header="translate('cover photo', 'ucfirst')" :collapsed="!imgStatusOfTabs.professional_cover"
          toggleable class="shadow-md border border-iron">
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4 flex-col">
                <FileUpload mode="basic" name="demo[]" accept="image/*" :chooseLabel="translate('send file', 'ucfirst')"
                  customUpload :maxFileSize="2097152" @select="updateProfessionalBackgroundCover"
                  @uploader="saveProfessionalCoverPhoto()" class="bg-sea-serpent" />
                <img v-if="professionalBackgroundCoverImg" :src="professionalBackgroundCoverImg" alt="Image"
                  class="shadow-md rounded-xl w-full sm:w-64" />
              </li>
            </ul>
          </div>
        </Panel>
        <Panel :header="translate('profile photo', 'ucfirst')" :collapsed="!imgStatusOfTabs.professional_photo" toggleable
          class="shadow-md border border-iron">
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4 flex-col">
                <FileUpload mode="basic" name="demo[]" accept="image/*" :chooseLabel="translate('send file', 'ucfirst')"
                  customUpload :maxFileSize="2097152" @select="updateProfessionalLogo" @uploader="saveProfessionalLogo()"
                  class="bg-sea-serpent" />
                <img v-if="professionalLogoImg" :src="professionalLogoImg" alt="Image"
                  class="shadow-md rounded-full w-full sm:w-64 sm:h-64" />
              </li>
            </ul>
          </div>
        </Panel>
      </aside>
      <!-- Fim barra lateral direita -->
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Panel from "primevue/panel";
import FileUpload from "primevue/fileupload"; 
import requestHandler from '@/composables/requestHandler';
import { translate } from "@/composables/translate"; 
import store from "@/store";
import Dropdown from "primevue/dropdown"; 
import { getOnPublic, isImageValid } from "@/composables/imageChecker"; 
import utils from "@/composables/utils";
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast"; 

import LoaderComponent from "@/components/Utils/LoaderComponent.vue";

// ############ Chamando componentes e composables ############


// ############ Declaração de variáveis ############
let toast = useToast();
let isLoading = ref(false);

let imgStatusOfTabs = ref({
  professional_photo: false,
  professional_cover: false
})
let personInformation = reactive(store.getters.getPerson);
let professionalInformation = reactive(store.getters.getProfessional);
let imgPrefix = 'data:image/png;base64,';
let professionalLogoImg = ref(null); 
let professionalBackgroundCoverImg = ref(null);
 
let requiredParameters = {
  professional: [ 
    {name: 'professional_firstname', message: translate('professional first name is required', 'ucfirst')},
    {name: 'professional_lastname', message: translate('professional last name is required', 'ucfirst')}, 
    {name: 'professional_title', message: translate('professional title is required', 'ucfirst')},
    {name: 'professional_email', message: translate('professional email is required', 'ucfirst')}
  ],
  person: [
    {name: 'person_username', message: translate('a user name is required', 'ucfirst')},
    {name: 'person_email', message: translate('an email is required', 'ucfirst')}
  ]
}

let comboData = reactive({
  combos: [
    'ddi', 'currency', 'language'
  ],
  data: {  
    ddi: [],
    currency: [],
    language: []
  },
  infoToGather: [ 
    {attr: 'professional_ddi',   key: 'ddi'}, 
    {attr: 'person_ddi',    key: 'ddi'},
    {attr: 'currency',      key: 'currency'},
    {attr: 'person_langue', key: 'language'}
  ],
  loading: { 
    ddi: true,
    currency: true,
    language: true
  }
})

let personProfile = ref({
  person_email: personInformation.person_email,
  person_username: personInformation.person_username,
  person_ddi: personInformation.person_ddi,
  person_phone: personInformation.person_phone,
  person_langue: personInformation.person_langue,
  currency: personInformation.currency,
});

let professionalProfile = ref({
  professional_email: professionalInformation.person_email,
  professional_firstname: professionalInformation.person_username,
  professional_lastname: professionalInformation.person_ddi,
  professional_title: professionalInformation.person_ddi,
  professional_phone: professionalInformation.person_phone,
  professional_ddi: professionalInformation.person_langue,
  professional_photo: professionalInformation.currency,
  professional_cover: professionalInformation.currency, 
});

// ############ Criação de Métodos/Funções ############

function handleProfessionalImages() {
  let professional = store.getters.getProfessional;
  if (![null, ''].includes(professional.professional_photo) && professional.professional_photo.length > 0) {
    professionalLogoImg.value = `${imgPrefix}${professional.professional_photo}`;
    imgStatusOfTabs.value.professional_photo = true;
  }
  if (![null, ''].includes(professional.professional_cover) && professional.professional_cover.length > 0) {
    professionalBackgroundCoverImg.value = `${imgPrefix}${professional.professional_cover}`;
    imgStatusOfTabs.value.professional_cover = true;
  }
}

async function updatePersonProfile(successMessage = false) {
  let form = utils.copyVariable(personProfile.value);
  for (let key of ['person_ddi', 'currency', 'person_langue']) {
    form[key] = form[key] != null && form[key]['code'] != undefined ? form[key]['code'] : null;
  }
  if (!areParemetersValid('person'))
    return;
  if (!['', null].includes(form['person_ddi']) && ['', null].includes(form['person_phone'])) {
    highlightInput('ddi');
    showToast(toast, translate('select a ddi to match the user phone'), 'error');
    return;
  }
  if (['', null].includes(form['person_ddi']) && !['', null].includes(form['person_phone'])) {
    highlightInput('person_phone');
    showToast(toast, translate('enter a user phone to match the selected ddi'), 'error');
    return;
  }
  highlightInput('person_ddi', false);
  highlightInput('person_phone', false);
  isLoading.value = true;
  const response = await requestHandler.executeRequest('api/person/update', form);
  isLoading.value = false;
  if (!response.success) {
    showToast(toast, response.message, 'error');
    return;
  }
  let message = !successMessage ? translate('person info updated', 'ucfirst') : successMessage;
  showToast(toast, message);
  store.dispatch('savePerson', response.data);
}

async function updateprofessionalProfile(successMessage = false) {
  let form = utils.copyVariable(professionalProfile.value);
  for(let key of ['updateProfessionalLogo_ddi', 'updateProfessionalLogo_type']){
    form[key] = form[key] != null && form[key]['code'] != undefined ? form[key]['code'] : null;
  }
  if(!areParemetersValid('professional'))
    return;
  if(!['',null].includes(form['professional_ddi']) && ['',null].includes(form['professional_phone'])){
    highlightInput('ddi');
    showToast(toast, translate('select a ddi to match the professional phone'), 'error');
    return;
  }
  if(['',null].includes(form['professional_ddi']) && !['',null].includes(form['professional_phone'])){
    highlightInput('professional_phone');
    showToast(toast, translate('enter a professional phone to match the selected ddi'), 'error');
    return;
  }
  highlightInput('ddi', false);
  highlightInput('professional_phone', false);
  isLoading.value = true;
  const response = await requestHandler.executeRequest('api/professional/update', form);
  isLoading.value = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  let message = !successMessage ? translate('professional info updated', 'ucfirst') : successMessage;
  showToast(toast, message);
  store.dispatch('saveProfessionalData', response.data);
}

function areParemetersValid(type = 'professional'){
  if(requiredParameters[type] == undefined)
    return false;
  let isValid = true;
  let arrayOfObj = requiredParameters[type];
  for(let obj of arrayOfObj){
    if(['',null].includes(professionalProfile.value[obj.name])){
      highlightInput(obj.name);
      showToast(toast, obj.message, 'error');
      isValid = false;
      break;
    }else{
      highlightInput(obj.name, false);
    }
  }
  return isValid;
}

async function updateProfessionalLogo(event) {
  const files = event.files;
  files.forEach(file => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let response = isImageValid(file);
      if (!response.status) {
        showToast(toast, response.message, 'error');
        return;
      }
      professionalLogoImg.value = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}

async function saveProfessionalLogo(){
  professionalProfile.value.professional_photo = professionalLogoImg.value.split(',')[1];
  updateprofessionalProfile(translate('Professional logo updated', 'ucfirst'));
}

async function saveProfessionalCoverPhoto(){
  professionalProfile.value.professional_cover = professionalBackgroundCoverImg.value.split(',')[1];
  updateprofessionalProfile(translate('Professional cover photo updated', 'ucfirst'));
}

async function updateProfessionalBackgroundCover(event) {
  const files = event.files;
  files.forEach(file => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let response = isImageValid(file);
      if(!response.status){
        showToast(toast, response.message, 'error');
        return;
      }
      professionalBackgroundCoverImg.value = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}  

  
function highlightInput(id, highlight = true) {
  let el = document.querySelector(`#label-${id}`);
  if (![undefined, null].includes(el)) {
    if (highlight) {
      el.style.color = 'red';
      el.click();
    } else {
      el.style.color = '';
    }
  }
}



// ############ Ciclos de Vida/Hooks ############

onMounted(() => {
  
  handleProfessionalImages();
})

</script>
