<template>
  <div class="h-screen">

    <section class="px-5 lg:px-8 mt-10 mb-6">
      <div class="flex flex-col md:flex-row justify-between items-center p-5 lg:p-8 bg-white shadow-lg rounded-lg">
        <span>
          <h1 class="text-oxford-blue mb-2 text-3xl font-semibold">{{ translate('Manage Company', 'ucfirst') }}</h1>
          <p class="text-base text-gray-500 font-normal">Gerencie os recrutadores para sua empresa e os seus
            acessos.</p>
        </span>
        <span class="">
          <a href="#" @click="visible = true"
            class="text-lg font-medium underline text-sea-serpent cursor-pointer">See invitation</a>

        </span>
      </div>
    </section>

    <Dialog v-model:visible="visible" modal :header="translate('My Invitations', 'ucfirst')" :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <ul>
          <li
            class="flex justify-between text-oxford-blue font-semibold px-5 py-3 mb-1.5 bg-sea-serpent oxford-blue gray-200">
            <h3 class="w-1/3">{{ translate('Company', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('Contact', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('Status', 'ucfirst') }}</h3>
            <h3 class="w-1/5 md:pl-4">{{ translate('Add at', 'ucfirst') }}</h3>
            <h3 class="w-1/6 md:pl-4">{{ translate('Actions', 'ucfirst') }}</h3>
          </li>

          <li v-for="(invitation, index) in myInvitations" :key="index"
            class="flex justify-between items-center p-3 border-b border-iron">
            <!-- Nome e foto da empresa -->
            <div class="flex items-center gap-3 w-1/3">
              <img src="@/assets/images/avatar.png" class="w-9 rounded-full">
              <span>
                <h2 class="text-oxford-blue font-medium">{{ invitation.person_username }}</h2>
                <small class="text-sm text-gray-500 font-normal">{{ invitation.person_email }}</small>
              </span>
            </div>
            <p class="flex items-center px-4 w-1/5">{{ invitation.person_ddi }} {{ invitation.person_phone }}</p>
            <span class="px-4 w-1/3">
              <Tag :value="invitation.status" :severity="invitation.status == 'active' ? 'success' : 'danger'"
                class="m-1"></Tag>
            </span>
            <!-- data adicionado recrutador -->
            <strong class="flex items-center px-4 w-1/5">{{ invitation.created_at }}</strong>
            <span class="flex flex-wrap items-center px-4 w-1/6">
              <Tag @click="refuseInvitation(invitation.company_recruiter_id)" class="m-1 cursor-pointer"
                icon="pi pi-trash" value="Eliminar" severity="danger"></Tag>
              <Tag @click="acceptInvitation(invitation.company_recruiter_id)" class="m-1 cursor-pointer"
                icon="pi pi-trash" value="Eliminar" severity="success"></Tag>
            </span>
          </li>
        </ul>
    </Dialog>
    <!-- <Dialog v-model:visible="visible" modal header="Header" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        </Dialog> -->


    <!-- Seção lista recrutadores -->
    <section class="w-full lg:px-8 mb-20">
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <ul>
          <li
            class="flex justify-between text-oxford-blue font-semibold px-5 py-3 mb-1.5 bg-sea-serpent oxford-blue gray-200">
            <h3 class="w-1/3">{{ translate('Company', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('Contact', 'ucfirst') }}</h3>
            <h3 class="w-1/3 md:pl-4">{{ translate('Status', 'ucfirst') }}</h3>
            <h3 class="w-1/5 md:pl-4">{{ translate('Add at', 'ucfirst') }}</h3>
            <h3 class="w-1/6 md:pl-4">{{ translate('Actions', 'ucfirst') }}</h3>
          </li>

          <li v-for="(invitation, index) in myInvitations" :key="index"
            class="flex justify-between items-center p-3 border-b border-iron">
            <!-- Nome e foto da empresa -->
            <div class="flex items-center gap-3 w-1/3">
              <img src="@/assets/images/avatar.png" class="w-9 rounded-full">
              <span>
                <h2 class="text-oxford-blue font-medium">{{ invitation.person_username }}</h2>
                <small class="text-sm text-gray-500 font-normal">{{ invitation.person_email }}</small>
              </span>
            </div>
            <p class="flex items-center px-4 w-1/5">{{ invitation.person_ddi }} {{ invitation.person_phone }}</p>
            <span class="px-4 w-1/3">
              <Tag :value="invitation.status" :severity="invitation.status == 'active' ? 'success' : 'danger'"
                class="m-1"></Tag>
            </span>
            <!-- data adicionado recrutador -->
            <strong class="flex items-center px-4 w-1/5">{{ invitation.created_at }}</strong>
            <span class="flex flex-wrap items-center px-4 w-1/6">
              <Tag @click="refuseInvitation(invitation.company_recruiter_id)" class="m-1 cursor-pointer"
                icon="pi pi-trash" value="Eliminar" severity="danger"></Tag>
              <Tag @click="acceptInvitation(invitation.company_recruiter_id)" class="m-1 cursor-pointer"
                icon="pi pi-trash" value="Eliminar" severity="success"></Tag>
            </span>
          </li>
        </ul>
      </div>

    </section>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
// import Button from "primevue/button"; 
import Tag from "primevue/tag";
// import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { translate } from "@/composables/translate";
import { showToast } from "@/composables/toast";
import Dialog from 'primevue/dialog';
import requestHandler from "@/composables/requestHandler";


// ############ Declaração de variáveis ############
const visible = ref(false);

let recruiter_id = ref(0);
const myInvitations = ref([])
const loading = ref(false);
const toast = useToast();

// ############ Criação de Métodos/Funções ############


// Lista Todos recrutadores pra empresa escolher
async function getMyInvitations() {

  const response = await requestHandler.executeRequest("api/recruiter/myinvitations");

  loading.value = false;

  if (!response.success) {
    showToast(toast, response.message, 'error');
    return;
  }
  else {
    myInvitations.value = response.data
  }

}

//  Aceita o convite enviado por uma empresa
async function acceptInvitation() {

  loading.value = true;

  if (recruiter_id.value && recruiter_id.value > 0) {
    const response = await requestHandler.executeRequest("api/recruiter/acceptinvitation", { recruiter_id });
    loading.value = false;
    showToast(toast, response.message, 'success', 5000, 'Invitation');
  }
}

//  Rejeita o convite enviado por uma empresa
async function refuseInvitation() {

  loading.value = true;

  if (recruiter_id.value && recruiter_id.value > 0) {
    const response = await requestHandler.executeRequest("api/recruiter/refuseinvitation", { recruiter_id });
    loading.value = false;
    showToast(toast, response.message, 'success', 5000, 'Invitation');
  }
}

// ############ Hooks ############


onMounted(() => {
  getMyInvitations()
})
</script>