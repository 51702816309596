<template>
  <div>
    <LoadingDialog :visible="loading" />

    <ConfirmationModal 
      :title="translate('are you sure?', 'ucfirst')"
      :text="`${translate('do you really want to create this profile', 'ucfirst')}: ${translate(selectedProfile)}`"
      :visible="confirmationModalVisibility"
      @emitResponse="saveNewProfile"
    />
    <div class="flex flex-col gap-y-10 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8">
      <h1 class="w-full font-bold text-2xl text-center">{{ translate('choose one of the following profile types to create it!', 'ucfirst') }}</h1>
      <div class="w-full md:mx-5 md:my-5 flex flex-col md:flex-row justify-around">
        
        <template v-for="(profile, index) of profiles" :key="index">
          <div 
            class="mt-10 md:mt-0 card border border-iron flex flex-col text-center w-full md:w-1/4 py-5 px-3 mx-0 md:mx-3 rounded bg-white shadow-lg rounded-lg" 
            v-if="!myProfiles.includes(profile.name)"
          >
            <h4 class="font-bold text-lg underline">{{ capitalizeWords(profile.title) }}</h4>
            <p class="my-8">{{ profile.description }}.</p>
            <Button
              :label="translate('create profile', 'upper')" 
              iconPos="right" 
              class="py-2 px-5 mt-auto w-full text-white rounded-lg bg-sea-serpent"
              @click.prevent="createProfile(profile.name)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
  import Button from 'primevue/button';
  import store from '@/store';
  import { computed, ref } from 'vue';
  import { translate } from '@/composables/translate';
  import requestHandler from '@/composables/requestHandler';
  import LoadingDialog from '@/components/Utils/LoadingDialog.vue';
  import ConfirmationModal from '@/components/Utils/ConfirmationModal.vue';
  import { capitalizeWords } from '@/composables/textUtils';
  import { useToast } from 'primevue/usetoast';
  import { showToast } from '@/composables/toast';
import { sleep } from '@/composables/sleep';

  let toast = useToast();
  let selectedProfile = ref('');
  let confirmationModalVisibility = ref(false);
  let loading = ref(false);
  let myProfiles = computed(() => store.getters.getLoggedUserProfiles);
  const profiles = [
    {
      name: 'professional', 
      title: translate('professional profile', 'ucfirst'), 
      description: translate('professional profiles can apply to jobs, create and edit their curriculum and more', 'ucfirst')
    },
    {
      name: 'company', 
      title: translate('company profile', 'ucfirst'), 
      description: translate('company profiles can create and edit jobs, see how applied to their job and more', 'ucfirst')
    },
    {
      name: 'recruiter', 
      title: translate('recruiter profile', 'ucfirst'), 
      description: translate('recruiter profiles can be part of a company or have their own, manage jobs of the companies, send invitations and more', 'ucfirst')
    }
  ];

  function createProfile(profileType = 'professional'){
    selectedProfile.value = profileType;
    confirmationModalVisibility.value = true;
  }
  
  async function saveNewProfile(event){
    confirmationModalVisibility.value = false;
    let confirmationResponse = event.confirmation != undefined ? event.confirmation : false
    if(!confirmationResponse)
      return;
    loading.value = true;
    const response = await requestHandler.executeRequest("/api/person/createprofile", {profile: selectedProfile.value}); 
    loading.value = false;
    if(!response.success){
      showToast(toast, response.message, 'error');
      return;
    }
    showToast(toast, translate('profile created', 'ucfirst'));
    store.dispatch('saveLoginInformation', response.data);
    sleep(1000);
    await requestHandler.redirect(`/dashboard/${store.getters.getLoggedAs}/${store.getters.getUserSlug}`);
  }
</script>  