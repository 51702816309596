<template>
  <div class="h-screen">
    <div class="flex flex-col gap-y-10 pt-12 pb-16 px-5 xs:px-8">
      <!-- Seção   -->
      <section
        class="col-span-1 md:col-span-4 flex justify-between items-center p-10 shadow-md rounded-md bg-white"
      >
        <div class="w-full flex flex-col">
          <span class="flex items-center gap-x-5">
            <h1 class="text-2xl font-semibold text-oxford-blue">
              Lista de vagas já divulgadas
            </h1>
          </span>
        </div>

        <span>
          <InputGroup
            class="w-full md:w-72 flex items-center pl-1.5 py-0 bg-white rounded-full border border-r-0 border-iron"
          >
            <InputGroupAddon>
              <i class="pi pi-search"></i>
            </InputGroupAddon>
            <InputText
              :unstyled="true"
              v-model.trim="job.title"
              type="text"
              id="text"
              placeholder="Faça sua busca"
              class="w-full px-2 py-1 outline-none"
            />
            <Button
              :unstyled="true"
              label="Buscar"
              class="py-1.5 px-4 font-semibold text-white rounded-r-full bg-sea-serpent"
            />
          </InputGroup>
        </span>
      </section>

      <!-- Seção -->
      <!-- Seção vagas recentes -->
      <DataTables :data="dataTable" />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Button from "primevue/button";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputText from "primevue/inputtext";

import DataTables from "@/components/Dashboard/Company/DataTables";

const job = ref({
  title: "",
});
job;
const dataTable = ref();
dataTable.value = [
  {
    name: "Director Esportivo",
    slug: "director-esportivo",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "RJ, Brasil",
    date: "17, Fevereiro, 2023",
    inventoryStatus: "ABERTO",
  },
  {
    name: "Engenheiro BDA",
    slug: "engenheiro-bda",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "Porto, Portugal",
    date: "17, Fevereiro, 2023",
    inventoryStatus: "Aberto",
  },
  {
    name: "Engenheiro de Software Backend PHP",
    slug: "engenheiro-de-software-backend-php",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "Madrid, Espanhã",
    date: "17, Fevereiro, 2023",
    inventoryStatus: "Fechado",
  },
  {
    name: "Cozinheiro",
    slug: "cozinheiro",
    image: "link-url-image",
    aplicants: 3453,
    ration: 3,
    address: "Orlando, USA",
    date: "17, Fevereiro, 2023",
    inventoryStatus: "Pendente",
  },
];

onMounted(() => {});
</script>
