<template>
    <Dialog
      :visible="true"
      modal
      :style="{ width: '35rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :draggable="false"
      :closable="false"
      class="relative bg-white rounded-lg"
    >
      <h1 class="text-2xl font-bold text-center text-oxford-blue">{{ translate('choose your profile', 'ucfirst') }}</h1>

      <div class="grid mt-5">
        <Button 
          v-if="canDisplay('professional')"
          :label="translate('professional', 'ucfirst')" 
          icon="pi pi-user" 
          iconPos="right" 
          class="py-2 px-5 text-white rounded-lg bg-sea-serpent mt-5 col-span-3"
          @click.prevent="performAction('professional')"
        />
        <Button 
          v-if="canDisplay('recruiter')"
          :label="translate('recruiter', 'ucfirst')" 
          icon="pi pi-clipboard" 
          iconPos="right" 
          class="py-2 px-5 text-white rounded-lg bg-sea-serpent mt-5 col-span-3"
          @click.prevent="performAction('recruiter')"
        />
        <Button 
          v-if="canDisplay('company')"
          :label="translate('company', 'ucfirst')" 
          icon="pi pi-building" 
          iconPos="right" 
          class="py-2 px-5 text-white rounded-lg bg-sea-serpent mt-5 col-span-3"
          @click.prevent="performAction('company')"
        />
      </div>
    </Dialog>
</template>

<script setup>
  import Dialog from "primevue/dialog";
  import Button from "primevue/button";
  import store from "@/store";
  import requestHandler from "@/composables/requestHandler";
  import Cookies from "js-cookie";
  import { encrypte } from "@/composables/crypto";
  import { translate } from "@/composables/translate";

  const props = defineProps({
    action: {
      type: String,
      required: false,
      default: 'login'
    }
  });

  const profilesAvaliables = {
    professional: 'professionals',
    recruiter: 'recruiters',
    company: 'companies'
  }

  async function performAction(profileName = ''){
    switch(props.action){
      case 'login':
        loginOnProfile(profileName);
      break;
      case 'register':
        emitProfileChosen(profileName);
      break;
      case 'switchAccount':
        changeProfile(profileName);
      break;
    }
  }

  async function loginOnProfile(profileName = ''){
    let userProfiles = store.getters.getUserType;
    if(!userProfiles.includes(profileName))
        profileName = 'profile';
    store.commit('setLoggedAs', profileName);
    Cookies.set('last_login_as', encrypte(profileName));
    let urlToGo = store.getters.getRouteToGoAfterLogin;
    if(urlToGo !== null){
      store.dispatch('saveRouteToGoAfterLogin', {url: null});
      await requestHandler.redirect(urlToGo);
      return;
    }
    await requestHandler.redirect(`/dashboard/${store.getters.getLoggedAs}/${store.getters.getUserSlug}`);
  }

  async function changeProfile(profileName = ''){
    let userProfiles = store.getters.getUserType;
    if(!userProfiles.includes(profileName))
        profileName = 'profile';
    store.commit('setLoggedAs', profileName);
    Cookies.set('last_login_as', encrypte(profileName));
    emitProfileChosen(profileName);
    await requestHandler.redirect(`/dashboard/${store.getters.getLoggedAs}/${store.getters.getUserSlug}`);
  }

  const emit = defineEmits({
    click: null,
    profileChosen: ({ profileName }) => {
      return profileName;
    }
  })

  function emitProfileChosen(profileName = ''){
    profileName = {
      app: profileName,
      api: profilesAvaliables[profileName]
    }
    emit('profileChosen', { profileName })
  }

  function canDisplay(name = ''){
    let myProfiles = store.getters.getLoggedUserNotLoggedInProfile;
    let loggedAs = store.getters.getLoggedAs;
    let display = false;
    switch(props.action){
      case 'register':
        display = true;
      break;
      case 'switchAccount':
        if(myProfiles.length < 1 || name == loggedAs || !myProfiles.includes(name)){
          display = false;
        }else{
          display = true;
        }
      break;
      case 'login':
        if(name == 'professional' && store.getters.getProfessional != null){
          display = true;
        }
        if(name == 'company' && store.getters.getCompany != null){
          display = true;
        }
        if(name == 'recruiter' && store.getters.getRecruiter != null){
          display = true;
        }
      break;
    }
    return display;
  }
</script>