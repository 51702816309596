<script setup>
import { ref, onMounted } from "vue";
import { RouterView } from "vue-router";
import Header from "@/components/HeaderComponent.vue";
import Footer from "@/components/FooterComponent.vue";
import CookiePopup from "@/components/CookiePopupComponent.vue";
// import cookiesHandler from "@/composables/cookiesHandler";

const visiblity_popup = ref(false);
function setCookieAcceptance(event) {
  document.cookie = "cookies-acceptance" + "=" + event;
  visiblity_popup.value = false;
}
const getCookieAcceptance = () => {
   let value = document.cookie.match("(^|;)?" + "cookies-acceptance" + "=([^;]*)(;|$)");
   return value ? unescape(value[2]) : null;
 };
let props = defineProps(['appKey']);
onMounted(() => {
  setTimeout(() => {
    
    getCookieAcceptance() == "true"
      ? (visiblity_popup.value = false)
      : (visiblity_popup.value = true);
  }, 5000);
});

</script>

<template>
  <div>
    <Header />

    <RouterView :appKey="props.appKey"> </RouterView>

    <CookiePopup
      @accept-cookie="setCookieAcceptance($event)"
      class="transition-all duration-[5000ms] ease-in-out"
      :class="visiblity_popup ? 'flex d-translate-x-[26rem]' : 'translate-y-56 hidden'"
    />

    <Footer @rebuildApplication="$emit('rebuildApplication')" />
  </div>
</template>
