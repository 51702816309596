<template>
  <LoadingDialog :visible="isLoading" />
  <div>
    <div
      class="grid grid-cols-5 xl:grid-cols-3 gap-4 xl:gap-x-6 pt-12 pb-16 px-4 xs:px-8 md:px-4 xl:px-8"
    >
      <!-- Seção dos campos formulário -->
      <div class="col-span-5 md:col-span-3 xl:col-span-2 flex flex-col gap-y-5">
 
        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
          <h1 class="mb-6 text-lg font-semibold">{{ translate('user information', 'ucfirst') }}</h1>
          <!-- Informações do usuário logado -->
          <form class="grid grid-cols-2 gap-x-4 gap-y-8 text-base font-normal">
            <span class="col-span-2 md:col-span-1 p-float-label">
              <InputText v-model.trim="personProfile.person_username" type="text" id="name"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="name">{{ translate('user name', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 md:col-span-1  p-float-label">
              <InputText v-model.trim="personProfile.person_email" type="email" id="email"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="email">{{ translate('email', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 w-full p-float-label">
              <LoaderComponent v-if="comboData.loading.ddi" />
                <Dropdown 
                  v-if="!comboData.loading.ddi"
                  id="ddi"
                  v-model="personProfile.person_ddi" 
                  :options="comboData.data.ddi"
                  filter 
                  optionLabel="name" 
                  :inputClass="'text-oxford-blue font-semibold'"
                  :placeholder="translate('ddi', 'upper')" 
                  class="w-full rounded-full border border-iron text-oxford-blue"
                >
                  <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                          <img 
                            :alt="slotProps.value.label" 
                            :src="getOnPublic(slotProps.value.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.value.name}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.value.ddi }} {{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps">
                      <div class="flex align-items-center">
                          <img 
                            :alt="slotProps.option.label" 
                            :src="getOnPublic(slotProps.option.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.option.code}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.option.ddi }} {{ slotProps.option.name }}</div>
                      </div>
                  </template>
              </Dropdown>
              <label v-if="!comboData.loading.ddi" id="label-person_ddi" for="ddi">{{ translate('ddi', 'upper') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <InputText 
                  v-model="personProfile.person_phone" 
                  id="person_phone" 
                  class="w-full flex px-3 py-2 rounded-full outline-none border border-iron text-oxford-blue font-semibold"
                />
              <label for="person_phone" id="label-person_phone" class="pl-3">{{ translate('phone', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <LoaderComponent v-if="comboData.loading.language" />
              <Dropdown
                v-model="personProfile.person_langue"
                :options="comboData.data.language"
                :loadind="comboData.loading.language"
                optionLabel="name"
                :placeholder="translate('select a language', 'ucfirst')"
                class="w-full rounded-full border border-iron text-oxford-blue"
                :inputClass="'text-oxford-blue font-semibold'"
                required
                filter
                id="language"
              />
              <label v-if="!comboData.loading.language" id="label-person_lang" for="language">{{ translate('language', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <LoaderComponent v-if="comboData.loading.currency" />
              <Dropdown
                v-model="personProfile.currency"
                :options="comboData.data.currency"
                :loadind="comboData.loading.currency"
                optionLabel="name"
                :placeholder="translate('select a currency', 'ucfirst')"
                class="w-full rounded-full border border-iron text-oxford-blue"
                :inputClass="'text-oxford-blue font-semibold'"
                required
                filter
                id="currency"
              />
              <label v-if="!comboData.loading.currency" id="label-currency" for="currency">{{ translate('currency', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2">
              <Button @click.prevent="updatePersonProfile()" :label="translate('save', 'ucfirst')" class="py-2 px-4 float-right text-white rounded-md bg-sea-serpent w-full md:w-auto" />
            </span>
          </form>
        </section>


        <!-- Informações da empresa -->
        <section class="w-full p-4 md:p-6 bg-white shadow-md rounded-md border border-iron">
          <h1 class="mb-6 text-lg font-semibold">{{ translate('company information', 'ucfirst') }}</h1>
          <form @submit.prevent="updateCompanyProfile()" class="grid grid-cols-2 gap-x-4 gap-y-8 mt-6 text-base font-normal">
            <span class="col-span-2 p-float-label">
              <InputText v-model.trim="companyProfile.company_name" type="text" id="name"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="name" id="label-company_name">{{ translate('company name', 'ucfirst') }} *</label>
            </span>
            <span class="col-span-2 lg:col-span-1 w-full p-float-label">
              <InputText :modelValue="companyProfile.company_email" type="text" id="company_email" 
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="company_email" id="label-company_type">{{ translate('email', 'ucfirst') }} *</label>
            </span>
            <div class="col-span-2 md:col-span-1 flex flex-wrap md:flex-none">
              <span class="w-full md:w-1/2 md:pr-3 p-float-label">
                <LoaderComponent v-if="comboData.loading.ddi" />
                <Dropdown 
                  v-if="!comboData.loading.ddi"
                  id="ddi"
                  v-model="companyProfile.company_ddi" 
                  :options="comboData.data.ddi"
                  filter 
                  optionLabel="name" 
                  :inputClass="'text-oxford-blue font-semibold'"
                  :placeholder="translate('ddi', 'upper')" 
                  class="w-full rounded-full border border-iron text-oxford-blue"
                >
                  <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                          <img 
                            :alt="slotProps.value.label" 
                            :src="getOnPublic(slotProps.value.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.value.name}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.value.ddi }} {{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps">
                      <div class="flex align-items-center">
                          <img 
                            :alt="slotProps.option.label" 
                            :src="getOnPublic(slotProps.option.flagSrc)" 
                            :class="`mr-2 flag flag-${slotProps.option.code}`" 
                            style="width: 18px" 
                          />
                          <div>{{ slotProps.option.ddi }} {{ slotProps.option.name }}</div>
                      </div>
                  </template>
                </Dropdown>
                <label v-if="!comboData.loading.ddi" id="label-company_id" for="ddi">{{ translate('ddi', 'upper') }}</label>
              </span>
              <span class="w-full md:w-1/2 md:pl-3 p-float-label mt-8 md:mt-0">
                <InputText 
                  v-model="companyProfile.company_phone" 
                  id="company_phone" 
                  class="w-full flex px-3 py-2 rounded-full outline-none border border-iron text-oxford-blue font-semibold"
                />
                <label for="company_phone" id="label-company_phone" class="md:pl-3">{{ translate('phone', 'ucfirst') }}</label>
              </span>
            </div>
            <div class="col-span-2 flex flex-wrap md:flex-none">
              <span class="w-full md:w-1/2 md:pr-3 p-float-label">
                <LoaderComponent v-if="comboData.loading.company_type" />
                <Dropdown
                  v-if="!comboData.loading.company_type"
                  v-model="companyProfile.company_type"
                  :options="comboData.data.company_type"
                  :loading="comboData.loading.company_type"
                  optionLabel="name"
                  :placeholder="translate('select an option', 'ucfirst')"
                  class="w-full rounded-full border border-iron text-oxford-blue"
                  :inputClass="'text-oxford-blue font-semibold'"
                  required
                  filter
                  id="company_type"
                />
                <label v-if="!comboData.loading.company_type" id="label-company_type" for="company_type">{{ translate('company type', 'ucfirst') }}</label>
              </span>
              <span class="w-full md:w-1/2 md:pl-3 p-float-label mt-8 md:mt-0">
                <InputText v-model.trim="companyProfile.company_register_number" type="text" id="company_register_number"
                  class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
                <label for="company_register_number" id="label-company_register_number" class="md:pl-3">{{ translate('registration number', 'ucfirst') }}</label>
              </span>
            </div>
            <span class="col-span-2 lg:col-span-1 p-float-label"> 
              <InputText v-model="companyProfile.company_website" type="text" id="company_website"
                class="w-full px-3 py-2 rounded-full border border-iron text-oxford-blue font-semibold" />
              <label for="company_website">{{ translate('website', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 lg:col-span-1 p-float-label">
              <InputNumber 
                v-model="companyProfile.company_number_employees" 
                inputId="company_number_employees" 
                class="w-full"
                inputClass="w-full px-3 py-2 rounded-full outline-none border border-iron text-oxford-blue font-semibold" 
              />
              <label for="company_number_employees">{{ translate('number of employees', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 p-float-label">
              <TextArea v-model.trim="companyProfile.company_description" id="company_description"
                class="w-full px-3 py-2 rounded-xl outline-nonel border border-iron text-oxford-blue font-semibold" />
              <label for="company_description">{{ translate('company description', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2 p-float-label">
              <TextArea v-model="companyProfile.company_benefits" id="company_benefits"
                class="w-full px-3 py-2 rounded-xl outline-nonel border border-iron text-oxford-blue font-semibold" />
              <label for="company_benefits">{{ translate('company benefits', 'ucfirst') }}</label>
            </span>
            <span class="col-span-2">
              <Button type="submit" :label="translate('save', 'ucfirst')" class="py-2 px-4 float-right text-white rounded-md bg-sea-serpent w-full md:w-auto" />
            </span>
          </form>
        </section>
      </div>

      <!-- Barra lateral direita -->
      <aside
        class="col-span-5 md:col-span-2 xl:col-span-1 flex flex-col gap-y-5 sticky top-24 h-[calc(100vh-theme(spacing.20))]">
        <Panel :header="translate('cover photo', 'ucfirst')" :collapsed="!imgStatusOfTabs.company_cover_photo" toggleable class="shadow-md border border-iron">
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4 flex-col">
                <FileUpload mode="basic" name="demo[]" accept="image/*" :chooseLabel="translate('send file', 'ucfirst')" customUpload
                  :maxFileSize="2097152" @select="updateCompanyBackgroundCover" @uploader="saveCompanyCoverPhoto()" class="bg-sea-serpent" />
                <img v-if="companyBackgroundCoverImg" :src="companyBackgroundCoverImg" alt="Image" class="shadow-md rounded-xl w-full sm:w-64" />
              </li>
            </ul>
          </div>
        </Panel> 
        <Panel :header="translate('profile photo', 'ucfirst')" :collapsed="!imgStatusOfTabs.company_logo" toggleable class="shadow-md border border-iron">
          <div class="m-0">
            <ul class="space-y-3 text-sm font-medium">
              <li class="flex justify-center items-center gap-2 my-4 flex-col">
                <FileUpload mode="basic" name="demo[]" accept="image/*" :chooseLabel="translate('send file', 'ucfirst')" customUpload 
                  :maxFileSize="2097152" @select="updateCompanyLogo" @uploader="saveCompanyLogo()" class="bg-sea-serpent" />
                <img v-if="companyLogoImg" :src="companyLogoImg" alt="Image" class="shadow-md rounded-full w-full sm:w-64 sm:h-64" />
               </li>
            </ul>
          </div>
        </Panel>
      </aside>
      <!-- Fim barra lateral direita -->
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Panel from "primevue/panel";
import FileUpload from "primevue/fileupload"; 
import TextArea from "primevue/textarea"; 
import requestHandler from '@/composables/requestHandler';
import { translate } from "@/composables/translate";
import companyService from "@/composables/companyData";
import store from "@/store";
import Dropdown from "primevue/dropdown";
import LoaderComponent from "@/components/Utils/LoaderComponent.vue";
import { getOnPublic, isImageValid } from "@/composables/imageChecker";
import utils from "@/composables/utils";
import { useToast } from "primevue/usetoast";
import { showToast } from "@/composables/toast";
import LoadingDialog from "@/components/Utils/LoadingDialog.vue";
 

// ############ Declaração de variáveis ############

// let jobStatus = ref(1);
let toast = useToast();
let personInformation = reactive(store.getters.getPerson);
let companyInformation = reactive(store.getters.getCompany);
let imgPrefix = 'data:image/png;base64,';
let companyLogoImg = ref(null);
let companyBackgroundCoverImg = ref(null);
let imgStatusOfTabs = ref({
  company_logo: false,
  company_cover_photo: false 
})

let personProfile = ref({
  person_email: personInformation.person_email,
  person_username: personInformation.person_username,
  person_ddi: personInformation.person_ddi,
  person_phone: personInformation.person_phone,
  person_langue: personInformation.person_langue,
  currency: personInformation.currency,
});
 
let companyProfile = ref({
  company_register_number: companyInformation.company_register_number,
  company_name: companyInformation.company_name,
  company_type: companyInformation.company_type,
  company_logo: companyInformation.company_logo,
  company_cover_photo: companyInformation.company_cover_photo,
  company_video: companyInformation.company_video,
  company_email: companyInformation.company_email,
  company_phone: companyInformation.company_phone,
  company_ddi: companyInformation.company_ddi,
  company_website: companyInformation.company_website,
  company_description: companyInformation.company_description,
  company_number_employees: companyInformation.company_number_employees,
  company_benefits: companyInformation.company_benefits,
  paying: companyInformation.paying,
});

let isLoading = ref(false);
let comboData = reactive({
  combos: [
    'company_type', 'ddi', 'currency', 'language'
  ],
  data: {
    company_type: [],
    ddi: [],
    currency: [],
    language: []
  },
  infoToGather: [
    {attr: 'company_ddi',   key: 'ddi'},
    {attr: 'company_type',  key: 'company_type'},
    {attr: 'person_ddi',    key: 'ddi'},
    {attr: 'currency',      key: 'currency'},
    {attr: 'person_langue', key: 'language'}
  ],
  loading: {
    company_type: true,
    ddi: true,
    currency: true,
    language: true
  }
})
let combosLoading = ref(true);

let requiredParameters = {
  company: [
    // {name: 'company_register_number', message: translate('company registration number is required', 'ucfirst')},
    {name: 'company_name', message: translate('company name is required', 'ucfirst')},
    // {name: 'company_type', message: translate('company type is required', 'ucfirst')},
    {name: 'company_email', message: translate('company email is required', 'ucfirst')}
  ],
  person: [
    {name: 'person_username', message: translate('a user name is required', 'ucfirst')},
    {name: 'person_email', message: translate('an email is required', 'ucfirst')}
  ]
}

// ############ Criação de Métodos/Funções ############

onMounted(() => {
  getComboData();
  handleCompanyImages();
})

function handleCompanyImages(){
  let company = store.getters.getCompany;
  if(![null,''].includes(company.company_logo) && company.company_logo.length > 0){
    companyLogoImg.value = `${imgPrefix}${company.company_logo}`;
    imgStatusOfTabs.value.company_logo = true;
  }
  if(![null,''].includes(company.company_cover_photo) && company.company_cover_photo.length > 0){
    companyBackgroundCoverImg.value = `${imgPrefix}${company.company_cover_photo}`;
    imgStatusOfTabs.value.company_cover_photo = true;
  }
}

async function getComboData(){
  
  combosLoading.value = true;
  for(let key of comboData.combos){
    comboData.loading[key] = true;
    const result = await companyService.getComboData(key, true);
    comboData.data[key] = result;
    comboData.loading[key] = false;
  }
  for(let obj of comboData.infoToGather){
    if(!['',null].includes(companyProfile.value[obj.attr])){
      let values = comboData.data[obj.attr] != undefined ? comboData.data[obj.attr] : comboData.data[obj.key];
      if(values == undefined)
        continue;
      for(let val of values){
        if(val.code == companyProfile.value[obj.attr]){
          companyProfile.value[obj.attr] = val;
        }
      }
    }
    if(!['',null].includes(personProfile.value[obj.attr])){
      let values = comboData.data[obj.attr] != undefined ? comboData.data[obj.attr] : comboData.data[obj.key];
      if(values == undefined)
        continue;
      for(let val of values){
        if(val.code == personProfile.value[obj.attr]){
          personProfile.value[obj.attr] = val;
        }
      }
    }
  }
  combosLoading.value = false;
}

async function updatePersonProfile(successMessage = false) {
  let form = utils.copyVariable(personProfile.value);
  for(let key of ['person_ddi', 'currency', 'person_langue']){
    form[key] = form[key] != null && form[key]['code'] != undefined ? form[key]['code'] : null;
  }
  if(!areParemetersValid('person'))
    return;
  if(!['',null].includes(form['person_ddi']) && ['',null].includes(form['person_phone'])){
    highlightInput('ddi');
    showToast(toast, translate('select a ddi to match the user phone'), 'error');
    return;
  }
  if(['',null].includes(form['person_ddi']) && !['',null].includes(form['person_phone'])){
    highlightInput('person_phone');
    showToast(toast, translate('enter a user phone to match the selected ddi'), 'error');
    return;
  }
  highlightInput('person_ddi', false);
  highlightInput('person_phone', false);
  isLoading.value = true;
  const response = await requestHandler.executeRequest('api/person/update', form);
  isLoading.value = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  let message = !successMessage ? translate('person info updated', 'ucfirst') : successMessage;
  showToast(toast, message);
  store.dispatch('savePerson', response.data);
}

async function updateCompanyProfile(successMessage = false) {
  let form = utils.copyVariable(companyProfile.value);
  for(let key of ['company_ddi', 'company_type']){
    form[key] = form[key] != null && form[key]['code'] != undefined ? form[key]['code'] : null;
  }
  if(!areParemetersValid('company'))
    return;
  if(!['',null].includes(form['company_ddi']) && ['',null].includes(form['company_phone'])){
    highlightInput('ddi');
    showToast(toast, translate('select a ddi to match the company phone'), 'error');
    return;
  }
  if(['',null].includes(form['company_ddi']) && !['',null].includes(form['company_phone'])){
    highlightInput('company_phone');
    showToast(toast, translate('enter a company phone to match the selected ddi'), 'error');
    return;
  }
  highlightInput('ddi', false);
  highlightInput('company_phone', false);
  isLoading.value = true;
  const response = await requestHandler.executeRequest('api/company/update', form);
  isLoading.value = false;
  if(!response.success){
    showToast(toast, response.message, 'error');
    return;
  }
  let message = !successMessage ? translate('company info updated', 'ucfirst') : successMessage;
  showToast(toast, message);
  store.dispatch('saveCompanyData', response.data);
}

function areParemetersValid(type = 'company'){
  if(requiredParameters[type] == undefined)
    return false;
  let isValid = true;
  let arrayOfObj = requiredParameters[type];
  for(let obj of arrayOfObj){
    if(['',null].includes(companyProfile.value[obj.name])){
      highlightInput(obj.name);
      showToast(toast, obj.message, 'error');
      isValid = false;
      break;
    }else{
      highlightInput(obj.name, false);
    }
  }
  return isValid;
}

function highlightInput(id, highlight = true){
  let el = document.querySelector(`#label-${id}`);
  if(![undefined, null].includes(el)){
    if(highlight){
      el.style.color = 'red';
      el.click();
    }else{
      el.style.color = '';
    }
  }
}

async function updateCompanyLogo(event) {
  const files = event.files;
  files.forEach(file => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let response = isImageValid(file);
      if(!response.status){
        showToast(toast, response.message, 'error');
        return;
      }
      companyLogoImg.value = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}

async function saveCompanyLogo(){
  companyProfile.value.company_logo = companyLogoImg.value.split(',')[1];
  updateCompanyProfile(translate('company logo updated', 'ucfirst'));
}

async function updateCompanyBackgroundCover(event) {
  const files = event.files;
  files.forEach(file => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let response = isImageValid(file);
      if(!response.status){
        showToast(toast, response.message, 'error');
        return;
      }
      companyBackgroundCoverImg.value = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}  

async function saveCompanyCoverPhoto(){
  companyProfile.value.company_cover_photo = companyBackgroundCoverImg.value.split(',')[1];
  updateCompanyProfile(translate('company cover photo updated', 'ucfirst'));
}

// ############ Observadores/watchs ############

// ############ Ciclos de Vida/Hooks ############
onMounted(() => {});
</script>
